import React from 'react';
import Pill from 'components/common/Pill';
import Popover from 'components/common/Popover';
import { ResponseBase, ComprehensionView } from 'models/comprehension/response/base';
import Constraints from 'models/comprehension/constraints';

import './PromptComprehension.scss';

const PillPopover = ({ value, label = '', inferred = false }) => {
    if (value === null || value === undefined) {
        return <></>;
    }

    // if (inferred) {
    //     return (
    //         <Popover trigger={<Pill highlighted>{value}</Pill>}>
    //             <>
    //                 <h2>How did we infer this?</h2>
    //                 <p>{inferredReason(label)}</p>
    //             </>
    //         </Popover>
    //     );
    // }

    return <Pill>{value}</Pill>;
}

type PromptComprehensionProps = {
    comprehension: any;
}

class PromptComprehension extends React.Component<PromptComprehensionProps, {}> {
    renderComprehensionView = (view: ComprehensionView) => {
        if (view.text) {
            return <p>{view.text}</p>;
        }

        if (view.attrs) {
            return Object.entries(view.attrs).map(([key, values]) => (
                <div key={key} className="attribute-group">
                    <span className="category">{key}: </span>
                    {values.map((value, index) => (
                        <PillPopover key={`${key}-${index}`} value={value} />
                    ))}
                </div>
            ));
        }

        return null;
    }

    render() {
        const { comprehension } = this.props;

        const promptResponse: ResponseBase = comprehension;
        // @ts-ignore
        const constraints: Constraints | undefined = promptResponse?.constraints;
        const comprehensionView = promptResponse.render(constraints);

        return (
            <div className="adapter-prompt-comprehension">
                {this.renderComprehensionView(comprehensionView)}
            </div>
        );
    }
}

export default PromptComprehension;