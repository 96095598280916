import React from 'react';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { AlertCircleIcon, CloseIcon } from 'components/common/Icon';

const SafeguardsModal = ({ onClose }) => (
    <Modal>
        <div className="onboarding-modal">
            <div className="onboarding-modal-head">
                <AlertCircleIcon color="#5C33CC" />
                <CloseIcon
                    onClick={onClose}
                    style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                />
            </div>

            <div className="onboarding-modal-body">
                <h3>How Adapter Safeguards Your Data</h3>

                <p>
                    Adapter&quot;s success depends on safeguarding your data and earning your trust.
                    Adapter only accesses your data when you ask it to and never independently without
                    your knowledge. It only stores data that can be useful to you and that you have given it permission to access.
                </p>

                <p>
                    Adapter is committed to protecting your privacy. Your data is only used to provide you
                    with personalized insights and recommendations. Adapter does not analyze your data for any
                    purpose that isn&quot;t directly beneficial to you. Your data is never sold or shared with
                    third parties. Adapter does not serve you ads.
                </p>

                <p>
                    Adapter uses state-of-the-art processes and controls to protect your data. Adapter&quot;s approach
                    is rooted in people and processes, recognizing that security measures are only as effective as the
                    individuals implementing them. This requires encrypting data wherever possible, implementing strict
                    access controls to compartmentalize information, and establishing monitoring systems that allow for
                    in depth auditing. Even the most advanced, or &quot;end-to-end encrypted&quot;, systems have an attack surface
                    and potential vulnerabilities. Adapter understands that securing your data depends on going further
                    to ensure account for technology and human factors.
                </p>
            </div>

            <div className="onboarding-modal-foot">
                <Button onClick={onClose}>Close</Button>
            </div>
        </div>
    </Modal>
);

export default SafeguardsModal;