import { ComprehensionResponse } from "..";
import Constraints from "../constraints";
import { ResponseBase, ComprehensionView, LiteralType } from "./base";

export class HotelResponse extends ResponseBase {
    check_in_date: string;
    check_out_date: string;
    query: string;
    locale: string;
    type?: LiteralType<"any" | "hotel" | "vacation rental">;
    num_adults: number;
    num_children: number;
    num_rooms: number;
    is_specific_hotel: boolean;
    amenities?: string[];
    notepad: string;

    constructor(data: Partial<HotelResponse>, comprehensionResponse: ComprehensionResponse) {
        super(comprehensionResponse);
        Object.assign(this, data);
    }

    render(constraints?: Constraints): ComprehensionView {
        const _type: string[] = [];
        if (this.type === "any" || !this.type) {
            _type.push("Hotel", "Vacation rental");
        } else if (this.type === "hotel") {
            _type.push("Hotel");
        } else if (this.type === "vacation rental") {
            _type.push("Vacation rental");
        }
        _type.push(`${this.num_adults} Adults`);

        this.num_children && _type.push(`${this.num_children} Children`);
        const features: string[] = [];
        const geographicFeature = this.renderGeographicFeature(constraints);
        if (geographicFeature) {
            features.push(geographicFeature);
        } else {
            features.push(`Near ${this.locale}`);
        }

        const distanceConstraint = this.renderDistanceConstraint(constraints);
        if (distanceConstraint) {
            features.push(distanceConstraint);
        }

        if (this.num_rooms) {
            features.push(this.num_rooms === 1 ? `1 room` : `${this.num_rooms} rooms`);
        }

        return {
            attrs: {
                Type: _type,
                Dates: [`Check in: ${this.check_in_date}`, `Check out: ${this.check_out_date}`],
                Features: features,
                ...(this.amenities && { Amenities: this.amenities }),
            }
        }
    }
}