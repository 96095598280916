import React from 'react';
import Card from 'components/common/Card';

const TextOption = ({ text }: { text: string }) => {
    return (
        <Card>
            <p>{text}</p>
        </Card>
    );
};

export default TextOption;