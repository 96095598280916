import React from 'react';
import { observer } from 'mobx-react';
import BasePage from 'components/common/BasePage';
import userMemoryStore, { MemoryCategory } from 'stores/MemoryStore';

type MemoryRecord = {
  content: string;
  timestamp: string;
  category: string;
};

class UserMemory extends React.Component {
  componentDidMount() {
    userMemoryStore.fetchMemories();
  }

  handleCategoryChange = (category: string | null) => {
    userMemoryStore.setCategoryFilter(category);
    userMemoryStore.fetchMemories();
  };

  renderCategoryFilters = () => {
    return (
      <div className="category-filters">
        <button onClick={() => this.handleCategoryChange(null)}>All</button>
        {Object.values(MemoryCategory).map(category => (
          <button 
            key={category} 
            onClick={() => this.handleCategoryChange(category)}
            className={userMemoryStore.categoryFilter === category ? 'active' : ''}
          >
            {category}
          </button>
        ))}
      </div>
    );
  };

  renderMemories = () => {
    const { loading, filteredMemories } = userMemoryStore;

    if (loading) {
      return <div>Loading memories...</div>;
    }

    if (filteredMemories.length === 0) {
      return <div>No memories found</div>;
    }

    return (
      <ul className="memory-list">
        {filteredMemories.map((memory: MemoryRecord, index: number) => (
          <li key={index} className="memory-item">
            <p className="memory-content">{memory.content}</p>
            <span className="memory-timestamp">{new Date(memory.timestamp).toLocaleString()}</span>
            <span className="memory-category">{memory.category}</span>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    return (
      <BasePage>
        <div className="user-memory">
          <h2>User Memories</h2>
          {this.renderCategoryFilters()}
          {this.renderMemories()}
        </div>
      </BasePage>
    );
  }
}

export default observer(UserMemory);