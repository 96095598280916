import React from 'react';

const Compass = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 81 81" fill="none">
        <path
            d="M40.4388 73.5827C58.8483 73.5827 73.7721 58.6588 73.7721 40.2494C73.7721 21.8399 58.8483 6.91602 40.4388 6.91602C22.0293 6.91602 7.10547 21.8399 7.10547 40.2494C7.10547 58.6588 22.0293 73.5827 40.4388 73.5827Z" 
            strokeWidth="3"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M49.5125 27.8026C51.141 27.2597 51.9552 26.9883 52.4967 27.1814C52.9679 27.3494 53.3387 27.7202 53.5067 28.1914C53.6998 28.7329 53.4284 29.5472 52.8856 31.1757L47.9271 46.0511C47.7725 46.5149 47.6952 46.7468 47.5635 46.9394C47.4468 47.11 47.2994 47.2573 47.1288 47.374C46.9363 47.5058 46.7044 47.5831 46.2406 47.7377L31.3651 52.6961C29.7366 53.239 28.9224 53.5104 28.3809 53.3173C27.9097 53.1493 27.5389 52.7785 27.3709 52.3072C27.1778 51.7658 27.4492 50.9515 27.992 49.323L32.9505 34.4476C33.1051 33.9838 33.1824 33.7519 33.3141 33.5593C33.4308 33.3887 33.5782 33.2414 33.7488 33.1247C33.9413 32.9929 34.1733 32.9156 34.637 32.761L49.5125 27.8026Z" 
            strokeWidth="3"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Compass;