import React, { ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { EyeClosedIcon, EyeOpenIcon } from '../../common/Icon/index'

import './Input.scss';

export type PasswordProps = {
    labelText?: string | any;
    fieldId: string;

    value?: string | number;
    placeholder?: string;
    hintText?: string;
    disabled?: boolean;
    required?: boolean;

    errorText?: string;
    error?: boolean;

    selectConfig?: any;

    link?: {
        linkText: string;
        linkUrl: string;
    };

    onChange: (event: ChangeEvent<any>) => void;

    style?: any;
    darkMode?: boolean;
};

export default class Password extends React.Component<PasswordProps, any> {
    state = {
        showPassword: false
    }

    onShowHide = () => {
        const { showPassword } = this.state;
        this.setState({ showPassword: !showPassword });
    }
    
    renderTextInput = () => {
        const { showPassword } = this.state;

        const {
            fieldId,
            value,
            placeholder,
            errorText,
            error,
            disabled = false,
            onChange
        } = this.props;

        return (
            <div className="inputWithIconButton">
                <input
                    id={fieldId}
                    className={errorText || error ? 'error' : ''}
                    name={fieldId}
                    type={showPassword ? 'text' : 'password'}
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                />

                {!showPassword && (
                    <EyeClosedIcon title="Show password" ariaRole="button" onClick={!error && !errorText ? this.onShowHide : () => {}} />
                )}

                {showPassword && (
                    <EyeOpenIcon title="Hide password" ariaRole="button" onClick={!error && !errorText ? this.onShowHide : () => {}} />
                )}
            </div>
        );
    }

    render() {
        const {
            fieldId,
            hintText,
            errorText,
            labelText,
            link,
            style = {},
            darkMode = false
        } = this.props;

        return (
            <div className={`adapter-form-field-input ${darkMode ? 'darkMode' : ''}`} style={style}>
                {labelText && (
                    <label htmlFor={fieldId}>
                        {labelText}
                    </label>
                )}

                {this.renderTextInput()}

                {link && (
                    <div className="text-sm text-right" style={{ width: '100%' }}>
                        <Link className="font-semibold" to={link.linkUrl}>
                            {link.linkText}
                        </Link>
                    </div>
                )}

                {!errorText && hintText && (
                    <p className="hintText">{hintText}</p>
                )}

                {errorText && (
                    <p className="errorText">{errorText}</p>
                )}
            </div>
        );
    }
}