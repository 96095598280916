import React from 'react';

const Facebook = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" fill="none">
        <g clip-path="url(#clip0_4633_25366)">
            <path
                d="M33.6155 16.9982C33.6155 7.82177 26.1766 0.382812 17.0002 0.382812C7.82372 0.382812 0.384766 7.82177 0.384766 16.9982C0.384766 25.2913 6.46074 32.1653 14.404 33.4117V21.8011H10.1852V16.9982H14.404V13.3376C14.404 9.17339 16.8846 6.8732 20.6799 6.8732C22.4972 6.8732 24.3992 7.19772 24.3992 7.19772V11.2867H22.3041C20.2402 11.2867 19.5963 12.5675 19.5963 13.8828V16.9982H24.2045L23.4678 21.8011H19.5963V33.4117C27.5396 32.1653 33.6155 25.2913 33.6155 16.9982Z"
                fill={color || "#9093A2"}
            />
        </g>
        <defs>
            <clipPath id="clip0_4633_25366">
                <rect width="33.2308" height="33.2308" fill="white" transform="translate(0.384766 0.382812)"/>
            </clipPath>
        </defs>
    </svg>
);

export default Facebook;