import React from 'react';
import { inject, observer } from 'mobx-react';
import { MartiniIcon, HotelIcon, PlaneIcon } from 'components/common/Icon';
import Intention from './index';
import Progress from '../Progress';

type SuggestionType = "flight" | "dining" | "lodging";

type PromptSuggestion = {
    type: SuggestionType;
    request: string;
    short_request: string;
    user_location: string;
    refs: string[];
}

const ICONS = {
    'flight': PlaneIcon,
    'lodging': HotelIcon,
    'dining': MartiniIcon,
} as const;

class IntentionList extends React.Component<{ NLPStore?: any; PromptStore?: any }> {
    componentDidMount(): void {
 
        const { PromptStore } = this.props;
        if (!PromptStore.loadingSuggestions) {
            PromptStore.getPromptSuggestions();
        }
    }

    render() {
        const { PromptStore, NLPStore } = this.props;

        if (PromptStore.loadingSuggestions) {
            return <Progress />;
        }

        // Access the suggestions array from the response structure
        const allSuggestions: PromptSuggestion[] = PromptStore.promptSuggestions || [];
        
        if (!allSuggestions.length) {
            return null;
        }

        return (
            <div className="adapter-intention-list">
                {allSuggestions.map((suggestion, i) => (
                    <Intention
                        key={i}
                        title=""
                        description={suggestion.short_request}
                        prompt={suggestion.request}
                        Icon={ICONS[suggestion.type]}
                        store={NLPStore.isCurrentTab ? NLPStore : PromptStore}
                    />
                ))}
            </div>
        );
    }
}

export default inject('NLPStore', 'PromptStore')(observer(IntentionList));