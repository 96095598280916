import React from 'react';

const PurpleCircle = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
            d="M0.333496 7.00016C0.333496 3.31826 3.31826 0.333496 7.00016 0.333496V0.333496C10.6821 0.333496 13.6668 3.31826 13.6668 7.00016V7.00016C13.6668 10.6821 10.6821 13.6668 7.00016 13.6668V13.6668C3.31826 13.6668 0.333496 10.6821 0.333496 7.00016V7.00016Z" 
            fill="#DBD4FB"
        />
        <path 
            d="M4.5 7C4.5 5.61929 5.61929 4.5 7 4.5V4.5C8.38071 4.5 9.5 5.61929 9.5 7V7C9.5 8.38071 8.38071 9.5 7 9.5V9.5C5.61929 9.5 4.5 8.38071 4.5 7V7Z" 
            fill="#7549F2"
        />
    </svg>
);

export default PurpleCircle;