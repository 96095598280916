import React from 'react';

const Spinner = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="110" viewBox="0 0 110 110" fill="none">
        <path 
            d="M19.6371 42.6267C26.7468 46.4117 27.386 60.3139 27.386 60.3139L27.8738 60.2821L27.5598 27.7579L0.396768 45.6401L0.642718 46.0626C0.642718 46.0626 12.5274 38.8416 19.6371 42.6267Z" 
            fill="#B390FE"
        />
        <path 
            d="M31.4227 85.4384C36.9845 79.6126 50.5442 82.7455 50.5442 82.7455L50.6451 82.2672L19.2408 73.8001L29.1367 104.779L29.6098 104.656C29.6098 104.656 25.8608 91.2643 31.4227 85.4384Z" 
            fill="#B390FE" 
        />
        <path 
            d="M75.8675 87.5794C72.247 80.3845 79.7502 68.6635 79.7502 68.6635L79.3335 68.4079L60.815 95.1472L93.3179 96.2254L93.361 95.7385C93.361 95.7385 79.4881 94.7742 75.8675 87.5794Z" 
            fill="#B390FE" 
        />
        <path 
            d="M91.4035 45.862C83.4786 47.3008 74.3571 36.7899 74.3571 36.7899L73.9941 37.1173L94.4069 62.44L104.63 31.5679L104.175 31.389C104.175 31.389 99.3284 44.4233 91.4035 45.862Z" 
            fill="#B390FE" 
        />
        <path 
            d="M56.4466 18.2183C55.162 26.1697 42.2122 31.2669 42.2122 31.2669L42.3999 31.7183L73.0778 20.9113L47.3775 0.983933L47.0572 1.3532C47.0572 1.3532 57.7311 10.2669 56.4466 18.2183Z" 
            fill="#B390FE" 
        />
    </svg>
);

export default Spinner;