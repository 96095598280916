import React from 'react';

const Apple = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" fill="none">
            <path
                d="M31.5388 16.9994C31.5388 25.0246 25.0329 31.5379 17.0004 31.5379C8.96788 31.5379 2.46191 25.0246 2.46191 16.9994C2.46191 8.9669 8.96788 2.46094 17.0004 2.46094C25.0329 2.46094 31.5388 8.9669 31.5388 16.9994Z" 
                fill={color || "#9093A2"}
            />
            <path 
                d="M23.8142 13.3196C23.7349 13.3659 21.8463 14.3426 21.8463 16.5082C21.9353 18.9779 24.2296 19.844 24.2689 19.844C24.2296 19.8903 23.9226 21.0239 23.0131 22.2121C22.2913 23.2357 21.4902 24.2677 20.2737 24.2677C19.1166 24.2677 18.7012 23.5855 17.366 23.5855C15.9322 23.5855 15.5265 24.2677 14.4287 24.2677C13.2122 24.2677 12.3517 23.1804 11.5906 22.1665C10.6018 20.8393 9.76139 18.7567 9.73172 16.757C9.71173 15.6974 9.92974 14.6558 10.4832 13.771C11.2643 12.5359 12.6588 11.6974 14.1816 11.6698C15.3485 11.6331 16.3869 12.4163 17.099 12.4163C17.7814 12.4163 19.0573 11.6698 20.5008 11.6698C21.1239 11.6704 22.7854 11.8453 23.8142 13.3196ZM17.0003 11.4582C16.7926 10.4905 17.366 9.52281 17.9001 8.90553C18.5825 8.15903 19.6603 7.65234 20.5898 7.65234C20.6491 8.62003 20.2731 9.56909 19.601 10.2603C18.9979 11.0068 17.9595 11.5688 17.0003 11.4582Z" 
                fill="white"
            />
    </svg>
);

export default Apple;