import React from 'react';

import {
    OneWayFlight, 
    RoundTripFlight,
    UtilityCosts
} from './FlightOption';

const normalizeUtilityCosts = (costs: UtilityCosts, allOptions: (OneWayFlight | RoundTripFlight)[]) => {
    const allCosts = allOptions.map(opt => {
        if ('utility_costs' in opt && opt.utility_costs) {
            return opt.utility_costs;
        } else if ('departure_option' in opt && opt.departure_option.utility_costs) {
            return opt.departure_option.utility_costs;
        }
        return null;
    }).filter((cost): cost is UtilityCosts => cost !== null);

    const normalizedCosts: Partial<UtilityCosts> = {};
    const averages: Partial<UtilityCosts> = {};

    Object.keys(costs).forEach(key => {
        if (['time_cost', 'stops_preference_cost'].includes(key)) {
            const values = allCosts.map(cost => cost[key]);
            const min = Math.min(...values);
            const max = Math.max(...values);
            const avg = values.reduce((sum, val) => sum + val, 0) / values.length;

            normalizedCosts[key] = max === min ? 100 : ((max - costs[key]) / (max - min)) * 100;
            averages[key] = avg;
        }
    });

    return { normalizedCosts, averages };
};

const FlightOptionUtilityTooltip = ({ option, allOptions }: { option: OneWayFlight | RoundTripFlight, allOptions: any[] }) => {
    let utilityCosts: UtilityCosts | undefined;
    let price: number;

    const isRoundTrip = (opt: OneWayFlight | RoundTripFlight): opt is RoundTripFlight => {
        return 'departure_option' in opt;
    };

    if (isRoundTrip(option)) {
        if (option.departure_option && typeof option.departure_option.price === 'number') {
            price = option.departure_option.price;
        } else {
            return null;
        }
        utilityCosts = option.utility_costs;
    } else {
        if (typeof option.price === 'number') {
            price = option.price;
        } else {
            return null;
        }
        utilityCosts = option.utility_costs;
    }

    if (!utilityCosts) {
        return null;
    }

    const { normalizedCosts } = normalizeUtilityCosts(utilityCosts, allOptions);

    const costLabels = {
        time_cost: 'Timing',
        stops_preference_cost: 'Stops',
        time_cost_after: 'Departure Time',
    };

    const avgPrice = allOptions.reduce((sum, opt) => {
        if (isRoundTrip(opt)) {
            return sum + (opt.departure_option.price || 0);
        } else {
            return sum + (opt.price || 0);
        }
    }, 0) / allOptions.length;

    const priceDifference = price - avgPrice;
    const pricePercentage = (priceDifference / avgPrice) * 100;

    return (
        <div className="flightOptionUtilityTooltip">
            {Object.entries(normalizedCosts).map(([key, value]) => (
                <div className="costRow" key={key}>
                    <div className="costLabel">
                        {costLabels[key as keyof typeof costLabels] || key}
                    </div>

                    <div className="costBar">
                        <div className="costFill" style={{ width: `${Math.abs(value)}%` }} />
                    </div>

                    <div className="costValue">
                        {key === 'time_cost'
                            ? `${value > 0 ? '+' : ''}${value.toFixed(2)}%`
                            : `${value.toFixed(2)}%`
                        }
                    </div>
                </div>
            ))}

            <div className="costRow">
                <div className="costLabel">Price</div>

                <div className="priceComparisonContainer">
                    <div
                        className="priceValue" 
                        style={{ color: priceDifference < 0 ? '#4caf50' : '#f44336' }}
                    >
                        {priceDifference < 0 ? '↓' : '↑'} ${Math.abs(priceDifference).toFixed(2)} ({Math.abs(pricePercentage).toFixed(2)}%)
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlightOptionUtilityTooltip;