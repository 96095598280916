import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from "react-toastify";

import StepHeader from './StepHeader';
import SafeguardsModal from '../modals/SafeguardsModal';
import DataHandlingModal from '../modals/DataHandlingModal';
import { GoogleIcon, CaretRightIcon, OutlookIcon, AppleIcon, LinkedInIcon, FacebookIcon } from 'components/common/Icon';
import OnboardingWrapper from '../index';
import { Text } from 'components/common/Typography';

const Connect = ({ OnboardingStore }: { OnboardingStore?: any }) => {
    const [ safeguardsOpen, setSafeguardsOpen ] = useState(false);
    const [ dataHandlingOpen, setDataHandlingOpen ] = useState(false);

    const toastOptions = {
        theme: 'dark',
        position: 'bottom-right',
        icon: false 
    };

    const requestThirdParty = (vendor) => {
        OnboardingStore.requestVendor(vendor)
            .then(() => {
                // @ts-ignore
                toast.success("Thank you for your feedback!", toastOptions);
            })
            .catch(() => {
                // @ts-ignore
                toast.error("Failed to send feedback", toastOptions);
            });
    };

    return (
        <OnboardingWrapper>
            <StepHeader currentStep={3} />

            <div className="adapter-two-col">

                <div className="adapter-two-col--title">
                    <div className="flex-1"></div>

                    <h1>
                        Connect an Account
                    </h1>

                    <p>
                        Adapter lets you retrieve your user data from major platforms so that it can be used to provide you
                        with powerful insights and personalized outcomes. Connecting an account brings your historical data
                        into Adapter and allows Adapter to retrieve new pieces of data when they are created.
                    </p>

                    <p>
                        You get to choose what categories of data you want Adapter to import from an account.
                    </p>

                    <p>
                        <a onClick={() => setSafeguardsOpen(true)}>Learn how Adapter safeguards your data</a>
                    </p>

                    <p>
                        <a onClick={() => setDataHandlingOpen(true)}>Learn what happens to your account data</a>
                    </p>

                    <div className="flex-1"></div>
                </div>

                <div className="adapter-two-col--content">
                    <div className="flex-1"></div>

                    <div className="connect-account-option">
                        <div><GoogleIcon /></div>
                         <div className="flex-1" onClick={() => { window.location.href = '/onboarding/controls'; }} style={{ cursor: 'pointer' }}>
                            <p>Google Mail &amp; Calendar</p>
                         </div>
                         <div><CaretRightIcon /></div>
                    </div>

                    <h3 className="connect-account-title">Coming Soon...</h3>

                    <div className="connect-account-option coming-soon">
                        <div><OutlookIcon /></div>
                         <div className="flex-1">
                            <p>Microsoft Outlook</p>
                         </div>
                         <div><Text fontSize='s'><a onClick={()=>requestThirdParty('outlook')}>Request</a></Text></div>
                    </div>

                    <div className="connect-account-option coming-soon">
                        <div><AppleIcon /></div>
                         <div className="flex-1">
                            <p>iCloud</p>
                         </div>
                         <div><Text fontSize='s'><a onClick={()=>requestThirdParty('icloud')}>Request</a></Text></div>
                    </div>

                    <div className="connect-account-option coming-soon">
                        <div><FacebookIcon /></div>
                         <div className="flex-1">
                            <p>Facebook</p>
                         </div>
                         <div><Text fontSize='s'><a onClick={()=>requestThirdParty('facebook')}>Request</a></Text></div>
                    </div>

                    <div className="connect-account-option coming-soon">
                        <div><LinkedInIcon /></div>
                         <div className="flex-1">
                            <p>LinkedIn</p>
                         </div>
                         <div><Text fontSize='s'><a onClick={()=>requestThirdParty('linkedin')}>Request</a></Text></div>
                    </div>

                    <div className="flex-1"></div>
                </div>
            </div>

            {dataHandlingOpen && <DataHandlingModal onClose={() => setDataHandlingOpen(false)} />}
            {safeguardsOpen && <SafeguardsModal onClose={() => setSafeguardsOpen(false)} />}
        </OnboardingWrapper>
    );
}

export default inject('OnboardingStore')(observer(Connect));