import React from 'react';
import { inject, observer } from 'mobx-react';

import Button from "components/common/Button";
import { GlobeIcon, TargetIcon, CompassIcon } from 'components/common/Icon';
import OnboardingWrapper from '../index';

type WelcomeProps = {
    OnboardingStore?: any;
}

class Welcome extends React.Component<WelcomeProps, {}> {
    render () {
        return (
            <OnboardingWrapper>
                <div className="flex-1"></div>
        
                <h1 className="">Get Started with Adapter</h1>
        
                <p>
                    Adapter collaborates with you to turn intentions into real world outcomes. 
                    The setup process is important for adding the user information and data streams that make 
                    Adapter&apos;s capabilities possible.
                </p>
        
                <p>
                    <em>Getting started takes about 5-10 minutes. </em>
                </p>
        
                <div className="welcome-icons">
                    <div>
                        <GlobeIcon />
        
                        <h4>Unique Insights</h4>
        
                        <p>
                            Self-knowledge pulled directly from your data
                        </p>
                    </div>
        
                    <div>
                        <TargetIcon />
        
                        <h4>Delegated Tasks</h4>
        
                        <p>
                            Time consuming tasks taken off of your plate 
                        </p>
                    </div>
        
                    <div>
                        <CompassIcon />
        
                        <h4>Intelligent Suggestions</h4>
        
                        <p>
                            Guidance on how to best use your time
                        </p>
                    </div>
                </div>
        
                <Button
                    size="2xl"
                    onClick={() => { 
                        window.location.href = '/onboarding/trust';
                    }}
                >
                    Get Started &rarr;
                </Button>
        
                <div className="flex-1"></div>
            </OnboardingWrapper>
        );
    }
}

export default inject('OnboardingStore')(observer(Welcome));