import React from 'react';
import { inject, observer } from 'mobx-react';
import Notice from 'components/common/Notice';
import PromptComprehension from 'components/common/PromptComprehension';

import '../Option.scss';

type InvalidParametersProps = { 
    PromptStore?: any;
    message: string;
}

const InvalidParamters = ({ PromptStore, message }: InvalidParametersProps) => {
    return (
        <>
            <Notice text='Invalid Request' warning />

            <div data-test-id="errors.invalid_params">
                Adapter is unable to process this request due to the following invalid input.
                Please edit your request using the prompt bar below.
            </div>

            <PromptComprehension comprehension={PromptStore.comprehensionResponse} />

            <div>
                {message}
            </div>
        </>
    );
};

export default inject('PromptStore')(observer(InvalidParamters));