import React from 'react';

import './Pill.scss';

const Pill = ({ children, highlighted = false }) => {
    return (
        <span className={`adapter-pill ${highlighted && 'highlighted'}`}>{children}</span>
    );
};

export default Pill;