import React from 'react';

const LinkedIn = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" fill="none">
        <rect x="2.46191" y="2.46094" width="29.0769" height="29.0769" rx="14.5385" fill={color || "#9093A2"}/>
        <path
            d="M13.4882 10.4486C13.4882 11.4191 12.647 12.2059 11.6093 12.2059C10.5717 12.2059 9.73047 11.4191 9.73047 10.4486C9.73047 9.47815 10.5717 8.69141 11.6093 8.69141C12.647 8.69141 13.4882 9.47815 13.4882 10.4486Z"
            fill="white"
        />
        <path
            d="M9.98741 13.4975H13.1991V23.2299H9.98741V13.4975Z"
            fill="white"
        />
        <path
            d="M18.37 13.4975H15.1583V23.2299H18.37C18.37 23.2299 18.37 20.166 18.37 18.2503C18.37 17.1005 18.7627 15.9456 20.3292 15.9456C22.0996 15.9456 22.0889 17.4503 22.0807 18.6161C22.0699 20.1399 22.0957 21.6949 22.0957 23.2299H25.3074V18.0933C25.2802 14.8135 24.4256 13.3023 21.6139 13.3023C19.9442 13.3023 18.9091 14.0603 18.37 14.7461V13.4975Z" 
            fill="white"
        />
    </svg>
);

export default LinkedIn;