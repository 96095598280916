import * as React from "react";

import './Arrow.scss';

export const Arrow = () => (
    <div className="adapter-delight-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
            <path d="M11.9588 12.7654C18.0147 7.12671 31.6563 11.2291 31.6563 11.2291L31.7903 10.7431L0.274646 -0.00507868L8.37246 32.5304L8.86347 32.4344C8.86347 32.4344 5.90286 18.4041 11.9588 12.7654Z" fill="#7F56D9" fillOpacity="0.43"/>
        </svg>
    </div>
);