import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import dayjs from 'dayjs';

TimeAgo.addDefaultLocale(en);
export const timeAgo = new TimeAgo('en-US');

export const convertTo12Hour = (time24) => {
    if (!time24 || typeof time24 !== 'string' || !time24.includes(':')) {
        return time24; // Return original value if it's not a valid time string
    }

    const [hours, minutes] = time24.split(':');
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; // Convert 0 to 12 for midnight

    return `${hour12}:${minutes} ${ampm}`;
};

// "Thu, Aug 8"
export const formatDate_ShortHuman = value => dayjs(value).format('ddd, MMM D');

// "08/08/2024"
export const formatDate_MMDDYYY = value => dayjs(value).format('MM/DD/YYYY');

// "8:52am"
export const formatTime_AmPm = value => dayjs(value).format('h:mma');