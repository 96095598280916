import Constraints from "../constraints";
import { ResponseBase, ComprehensionView } from "./base";
import { ComprehensionResponse } from '../index';

function convertTo12Hour(time24: string): string {
    const [hours, minutes] = time24.split(':');
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minutes} ${ampm}`;
}

export class DiningResponse extends ResponseBase {
    time_range?: [string, string];
    time?: string;
    date: string;
    search_query: string;
    locale: string;
    party_size: number;
    is_nearby?: boolean;
    nearby_landmark?: string;
    is_specific_restaurant: boolean;
    notepad?: string;

    constructor(data: any, comprehensionResponse: ComprehensionResponse) {
        super(comprehensionResponse);
        Object.assign(this, data);
    }

    render(constraints?: Constraints): ComprehensionView {
        const _type: string[] = [];
        _type.push(this.renderField(this.date, 'result', 'date'));
        
        if (this.time) {
            _type.push(this.renderField(`At ${convertTo12Hour(this.time)}`, 'result', 'time'));
        } else if (this.time_range) {
            _type.push(this.renderField(`Within ${convertTo12Hour(this.time_range[0])} - ${convertTo12Hour(this.time_range[1])}`, 'result', 'time_range'));
        }
        
        if (this.is_specific_restaurant) {
            _type.push(this.renderField(`Specific Restaurant`, 'result', 'is_specific_restaurant'));
        }

        const features: string[] = [];
        const geographicFeature = this.renderGeographicFeature(constraints);

        if (geographicFeature) {
            features.push(geographicFeature);
        } else {
            features.push(this.renderField(`Near ${this.locale}`, 'result', 'locale'));
        }

        const distanceConstraint = this.renderDistanceConstraint(constraints);
        if (distanceConstraint) {
            features.push(distanceConstraint);
        }

        return {
            attrs: {
                Type: _type,
                For: [this.renderField(this.party_size > 1 ? `${this.party_size} guests` : '1 guest', 'result', 'party_size')],
                Features: features
            }
        }
    }
}