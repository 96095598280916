import React from "react";
import { inject, observer } from "mobx-react";

import Feedback from "../Feedback";

interface FooterProps {
  style?: any;
  copy?: boolean;
  UserStore?: any;
}

const Footer = ({ style = {}, copy = false, UserStore }: FooterProps) => {
  return (
    <>
      {copy && (
        <footer className="h-16"  style={style}>
          <p>
            &copy; Adapter, {new Date().getFullYear()}
          </p>
        </footer>
      )}

      <Feedback />
    </>
  );
}

export default inject('UserStore')(observer(Footer));