import React from 'react';

const Send = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path 
            d="M10.4995 13.7502L20.9995 3.25017M10.6271 14.0782L13.2552 20.8362C13.4867 21.4316 13.6025 21.7293 13.7693 21.8162C13.9139 21.8915 14.0862 21.8916 14.2308 21.8164C14.3977 21.7297 14.5139 21.4322 14.7461 20.8371L21.3364 3.94937C21.5461 3.41219 21.6509 3.1436 21.5935 2.97197C21.5437 2.82292 21.4268 2.70596 21.2777 2.65616C21.1061 2.59883 20.8375 2.70364 20.3003 2.91327L3.41258 9.50361C2.8175 9.73584 2.51997 9.85195 2.43326 10.0189C2.35809 10.1635 2.35819 10.3358 2.43353 10.4804C2.52043 10.6472 2.81811 10.763 3.41345 10.9945L10.1715 13.6226C10.2923 13.6696 10.3527 13.6931 10.4036 13.7294C10.4487 13.7615 10.4881 13.801 10.5203 13.8461C10.5566 13.897 10.5801 13.9574 10.6271 14.0782Z" 
            stroke={color}
            strokeWidth="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
        />
    </svg>
);

export default Send;