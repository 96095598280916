import React, { ChangeEvent, FormEvent } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';

import Modal from 'components/common/Modal';
import { AlertCircleIcon, CloseIcon } from 'components/common/Icon';
import BaseForm from 'components/forms/BaseForm';

type VerifyPhoneModalProps = {
    UserStore?: any;
    onClose: () => void;
}

type VerifyPhoneModalState = {
    passcode: string;
    isLoading: boolean;
}

class VerifyPhoneModal extends React.Component <VerifyPhoneModalProps, VerifyPhoneModalState> {
    state = {
        passcode: undefined,
        isLoading: false
    }

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { UserStore } = this.props;
        const { passcode } = this.state;

        this.setState({ isLoading: true }, () => {
            UserStore.sendPhoneVerification(passcode)
                .then(() => {
                    window.location.href = '/onboarding/connect';
                })
                .catch(() => {
                    this.setState({ isLoading: false }, () => {
                        toast.error(
                            "Incorrect phone verification. Please check the entered code and try again.", 
                            { theme: 'dark', position: 'bottom-right', icon: false }
                        );
                    });
                })
        });
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        // @ts-ignore
        this.setState({ passcode: value });
    };

    render() {
        const { passcode, isLoading } = this.state;
        const { onClose } = this.props;

        const config = [
            {
                labelText: "Verification Code",
                fieldId: "passcode",
                type: "text",
                value: passcode,
                required: true,
                errorText: passcode !== undefined && passcode?.length === 0 ? "Verification code is required" : undefined
            }
        ];

        return (
            <Modal>
                <div className="onboarding-modal">

                    <div className="onboarding-modal-head">
                        <AlertCircleIcon color="#5C33CC" />
                        <CloseIcon
                            onClick={onClose}
                            style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                        />
                    </div>

                    <div className="onboarding-modal-body">
                        <h3>Verify your mobile phone number</h3>

                        <p>
                            Please check your mobile phone for a text message containing a verification code and enter it here:
                        </p>

                        <BaseForm
                            config={config}
                            isLoading={isLoading}
                            buttonText="Verify Phone Number"
                            onChange={this.onChangeHandler}
                            onSubmit={this.onSubmitHandler}
                            style={{ minWidth: '50%' }}
                        />
                    </div>

                    <div className="onboarding-modal-foot"></div>
                </div>
            </Modal>
        );
    }
}

export default inject('UserStore')(observer(VerifyPhoneModal));