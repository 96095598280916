import React from 'react';

import { CloseIcon, LinkBrokenIcon } from 'components/common/Icon';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';

import './DisconnectAccountModal.scss';

type DisconnectAccountModalProps = {
    onCancel: () => void;
    onDisconnect: () => void;
}

const DisconnectAccountModal = ({ onCancel, onDisconnect }: DisconnectAccountModalProps) => (
    <Modal>
        <div className="disconnect-confirm">
            <div className="disconnect-confirm-head">
                <LinkBrokenIcon color="#5C33CC" />
                <CloseIcon
                    onClick={onCancel}
                    style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                />
            </div>

            <div className="disconnect-confirm-body">
                <h3>Disconnect Account?</h3>

                <p>
                    Adapter will no longer be able to view and process raw data from this account. 
                    This includes both new and historical data. 
                </p>

                <p>
                    Copies of raw data from this account stored within Adapter will not be deleted. 
                    Information gathered from processing this raw data and added to your knowledge base as insights will also not be deleted. 
                    In order to fully delete collected insights and data please contact customer support. 
                </p>
            </div>

            <div className="disconnect-confirm-foot">
                <Button onClick={onDisconnect}>Disconnect</Button>
            </div>
        </div>
    </Modal>
);

export default DisconnectAccountModal;