import React from 'react';
import { timeAgo } from 'util/Time';
import classNames from 'classnames';

import { AdapterIcon, UserIcon } from '../Icon';

import './Message.scss';

type MessageProps = {
    name: string;
    content: any;
    time?: number;
}

export const Message = ({ name = '', content, time }: MessageProps) => (
    <div
        className={classNames({
            'adapter-chat-message': true,
            'fade-in': true,
            user: name !== 'Adapter'
        })}
    >
        <div className={`avatar ${name === 'Adapter' ? 'adapter' : ''}`}>
            {name === 'Adapter' && (<AdapterIcon title="Adapter" color="#fff" />)}
            {name !== 'Adapter' && (<UserIcon title={name} color="#fff" />)}
        </div>

        <div className="message">
            <>
                <p>{name} <span className="timeago">{timeAgo.format(new Date(time))}</span></p>
                <div className="content">{content}</div>    
            </>
        </div>
    </div>
);