import React from "react";

import './Input.scss';

import { InputProps } from "./Input";

export interface CheckboxProps extends Omit<InputProps, "value" | "placeholder" | "type" | "hintText" | "errorText"> {
    checked?: boolean;
};

export default class Checkbox extends React.Component<CheckboxProps, any> {
    render() {
        const {
            fieldId,
            labelText,
            disabled = false,
            checked = false,
            ariaLabel = '',
            onChange,
        } = this.props;

        return (
            <div className="flex flex-row justify-items-center">
                <input
                    id={fieldId}
                    className="mr-2"
                    name={fieldId}
                    type="checkbox"
                    onClick={onChange}
                    checked={checked}
                    disabled={disabled}
                    aria-label={ariaLabel}
                />
                <p className="flex-1">
                    {labelText}
                </p>
            </div>
        );
    }
}