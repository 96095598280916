import React from "react";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";

interface ActivationProps {
  AuthStore?: any;
}

class SignUpActivation extends React.Component<ActivationProps, {}> {
  componentDidMount(): void {
    const { AuthStore } = this.props;
    const token = new URLSearchParams(window.location.search).get('token');

    AuthStore.activate({ token })
      .then(() => {
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      })
      .catch(() => {
        toast.error("Something went wrong, please try again.", { icon: false });
      });
  }

  render() {
    return (
      <div className="adapter-fullscreen-purple">

        <div className="flex-1"></div>

        <h1>Activating your account...</h1>

        <div className="flex-1"></div>
      </div>
    );
  }
}

export default inject("AuthStore")(observer(SignUpActivation));
