import React, { ChangeEvent, FormEvent } from "react";
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from "components/common/BasePage";
import BaseForm from "components/forms/BaseForm";
import Tabs from "components/common/Tab";
import Card from "components/common/Card";
import Progress from "components/common/Progress";

import "../../brand/index.scss";

interface UserKnowledgePageProps {
    UserStore?: any;
}

interface UserKnowledgePageState {
    preferredHomeAirport: string | undefined;
    preferredAirline: string | undefined;
    favoriteCuisines: string | undefined;

    isLoading: boolean;
}

class UserKnowledgePage extends React.Component<UserKnowledgePageProps, UserKnowledgePageState> {
    initialValues: Partial<UserKnowledgePageState> = {};

    state: UserKnowledgePageState = {
        preferredHomeAirport: '',
        preferredAirline: '',
        favoriteCuisines: '',

        isLoading: true
    };

    componentDidMount(): void {
        const { UserStore } = this.props;
        
        UserStore.loadUserMetadata().then(() => {
            const initialState = {
                preferredHomeAirport: UserStore.metadata.preferredHomeAirport || '',
                preferredAirline: UserStore.metadata.preferredAirline || '',
                favoriteCuisines: UserStore.metadata.favoriteCuisines || '',
            };

            this.initialValues = { ...initialState };

            this.setState({ ...initialState, isLoading: false });
        });
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        //@ts-ignore
        this.setState({ [name]: value });
    };

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { UserStore } = this.props;
        const { preferredHomeAirport, preferredAirline, favoriteCuisines } = this.state;

        // Use current state for all fields
        const updatedMetadata = {
            preferredHomeAirport,
            preferredAirline,
            favoriteCuisines
        };

        // Filter out empty strings, but keep other falsy values like empty arrays or 0
        const filteredMetadata = Object.fromEntries(
            Object.entries(updatedMetadata).filter(([_, value]) => value !== '')
        );

        UserStore.updateUserMetadata(filteredMetadata)
            .then(() => {
                toast.success("Your preferences have been updated successfully.", { icon: false });
                // Update initialValues with all current values
                this.initialValues = { ...updatedMetadata };
            })
            .catch(() => {
                toast.error("Oops! Something went wrong, please try again.", { icon: false });
            });
    };

    render() {
        const { UserStore } = this.props;
        const { preferredHomeAirport, preferredAirline, favoriteCuisines, isLoading } = this.state;

        const config = [
            {
                labelText: "Preferred Home Airport",
                fieldId: "preferredHomeAirport",
                type: "suggestion",
                value: preferredHomeAirport,
                required: false
            },
            {
                labelText: "Preferred Airline",
                fieldId: "preferredAirline",
                type: "suggestion",
                value: preferredAirline,
                required: false
            },
            {
                labelText: "Favorite Cuisines",
                fieldId: "favoriteCuisines",
                type: "text",
                value: favoriteCuisines,
                required: false,
            }
        ];

        const tabs = [
            { title: 'Profile', url: '/settings/profile' },
            { title: 'Details', url: '/settings/user-knowledge', active: true },
            { title: 'Password', url: '/settings/password' },
            { title: 'Accounts', url: '/settings/accounts' },
        ];

        return (
            <AuthenticatedPage>
                <BasePage>
                    <div className="adapter-fullscreen-page">
                        <Tabs tabs={tabs} />

                        <div style={{ width: '80vw', maxWidth: '600px', alignSelf: 'center' }}>
                            <h3>User Knowledge</h3>
                            <p>Configure facts or preferences about you to enhance your user experience.</p>
                            <Card>

                                {!isLoading && (
                                    <BaseForm
                                        config={config}
                                        isLoading={UserStore.isLoading}
                                        buttonText="Save Changes"
                                        onChange={this.onChangeHandler}
                                        onSubmit={this.onSubmitHandler}
                                        style={{ minWidth: '50%' }}
                                    />
                                )}

                                {isLoading && (<Progress />)}
                            </Card>
                        </div>

                        <div className="flex-1"></div>
                    </div>
                </BasePage>
            </AuthenticatedPage>
        );
    }
}

export default inject("UserStore")(observer(UserKnowledgePage));
