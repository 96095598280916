import AuthenticatedPage from "components/common/AuthenticatedPage";
import UserMemory from './memories';

import "../../brand/index.scss";

const Memory = () => {
    return (
        <AuthenticatedPage>
            <UserMemory />
        </AuthenticatedPage>
    );
};

export default Memory;