import React from 'react';

import './Progress.scss';

const Progress = () => {
    return (
        <div className="adapter-progress">
            
        </div>
    );
}

export default Progress;