import React from 'react';

import './Card.scss';

const Card = ({ children, style, cls = '' }: any) => (
    <div className={`adapter-card ${cls}`} style={style}>
        {children}
    </div>
);

export default Card;