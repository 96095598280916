import React from 'react';
import L from "leaflet";

import Button from 'components/common/Button';
import Pill from 'components/common/Pill';

import './HotelOption.scss';

const MAP_LOCATION_COLOR = '#EF6820';

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3
});

type HotelResult = {
    name: string;
    type: 'hotel' | 'vacation rental';
    check_in_time: string;
    check_out_time: string;
    price?: number;
    description?: string;
    hotel_class?: string;
    amenities?: string[];
    website?: string;
    phone?: string;
    rating?: number;
    room_details?: any;
    address?: string;
    booking_url: string;
    gps_coordinates?: {
        lat: number;
        lng: number;
    }
}

type HotelOptionProps = {
    option: HotelResult;
    index: number;
};

interface HotelOptionState {
    map: L.Map | null;
}

class HotelOption extends React.Component<HotelOptionProps, HotelOptionState> {
    private mapRef: React.RefObject<HTMLDivElement>;

    constructor(props: HotelOptionProps) {
        super(props);

        this.state = {
            map: null
        };

        this.mapRef = React.createRef();
    }

    componentDidMount(): void {
        this.drawMap();
    }

    drawMap = () => {
        const { option } = this.props;
        const lat = option.gps_coordinates?.lat;
        const lng = option.gps_coordinates?.lng;

        if (!this.state.map && lat && lng && this.mapRef.current) {
            const zoomScale = 16;

            const initializedMap = L.map(this.mapRef.current, {
                zoomControl: false,
                attributionControl: false,
            }).setView([lat, lng], zoomScale);

            const tileLayer = L.tileLayer(
                "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ).addTo(initializedMap);

            tileLayer.getContainer().style.filter = "grayscale(100%)";

            L.svg({ clickable: true }).addTo(initializedMap);

            L.circleMarker([lat, lng], {
                color: MAP_LOCATION_COLOR,
                fillColor: MAP_LOCATION_COLOR,
                fillOpacity: 0.5,
                radius: 4
            }).addTo(initializedMap);

            this.setState({ map: initializedMap });
        }
    };

    render() {
        const { option, index } = this.props;
        const isTopPick = index === 0;
        const lat = option.gps_coordinates?.lat;
        const lng = option.gps_coordinates?.lng;

        return (
            <div className="adapter-intention-option">
                <div className="option-price-header">
                    <div className="option-price-header-wrapper">
                        <h3>{USDollar.format(option.price || 0)}</h3>
                        <p>{option.name}</p>
                    </div>
                    {isTopPick && <Pill highlighted>Best Match</Pill>}
                </div>

                <div className="option-details">
                    <ul>
                        {option.hotel_class && <li>{option.hotel_class}</li>}
                        <li>Check-In: {option.check_in_time}</li>
                        <li>Check-Out: {option.check_out_time}</li>
                    </ul>

                    <p>{option.description}</p>

                    {option.address && <p>{option.address}</p>}

                    {lat && lng && (
                        <div className="option-details-body-wrapper">
                            <div ref={this.mapRef} style={{ height: '300px', flex: 1 }}></div>
                        </div>
                    )}
                </div>

                {option.booking_url && (
                    <Button
                        size="2xl"
                        onClick={() => window.open(option.booking_url, '_blank')}
                        style={{ width: '100%', marginTop: '24px' }}
                    >
                        Book Now
                    </Button>
                )}
            </div>
        );
    }
}

export default HotelOption;