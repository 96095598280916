import React from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from "react-toastify";

import StepHeader from './StepHeader';
import Button from "components/common/Button";
import OnboardingStats from 'components/stats/OnboardingStats';
import Progress from 'components/common/Progress';
import OnboardingWrapper from '../index';

type AccountIngestProps = {
    AuthStore?: any;
    OnboardingStore?: any;
}

class AccountIngest extends React.Component<AccountIngestProps, {}> {
    state = {
        firstRender: true,
        connected: false
    }

    componentDidMount() {
        const { AuthStore } = this.props;
        const { firstRender } = this.state;

        if (firstRender) {
            const searchParams = new URL(window.location.href).searchParams;
            const code = searchParams.get('code');
            const error = searchParams.get('error');

            if (code || error) {
                this.setState({ firstRender: false }, () => {
                    AuthStore.oauth({ code, error })
                        .then(() => this.setState({ connected: true }))
                        .catch(() => toast.error(`oAuth error.`, { icon: false }));        
                });
            } else {
                this.setState({ 
                    firstRender: false,
                    connected: true
                });
            }
        }
    }

    onClick = () => {
        const { OnboardingStore } = this.props;

        OnboardingStore.stopPollingForStats();
        OnboardingStore.completeOnboarding()
            .then(() => {
                window.location.href = '/dashboard'
            });
    }

    render() {
        const { connected } = this.state;

        let body = (
            <>
                <h2>Importing account data...</h2>

                <p>Retrieving, categorizing, and processing your data for events, insights, and preferences.</p>

                <OnboardingStats />

                <p>
                    Depending on the size of your inbox, this may take a few minutes to complete. 
                    You can continue with the onboarding process in the meantime!
                </p>

                <Button size="2xl" onClick={this.onClick}>Continue &rarr;</Button>
            </>
        );

        if (!connected) {
            body = (
                <>
                    <h2>Connecting your account...</h2>

                    <p>Adapter is connecting your account, this may take a few moments.</p>

                    <Progress />
                </>
            );
        }

        return (
            <OnboardingWrapper>
                <StepHeader currentStep={5} />
                <div className="flex-1"></div>
                {body}
                <div className="flex-1"></div>
            </OnboardingWrapper>
        );
    }
}

export default inject("AuthStore", "OnboardingStore")(observer(AccountIngest));