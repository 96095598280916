import React from 'react';

const Lightbulb = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 21" fill="none">
        <path 
            d="M7.91602 18.8332H12.0827M8.33268 8.83317H11.666M9.99935 8.83317L9.99935 13.8332M12.4993 13.2718C14.4701 12.3356 15.8327 10.3268 15.8327 7.99984C15.8327 4.77818 13.221 2.1665 9.99935 2.1665C6.77769 2.1665 4.16602 4.77818 4.16602 7.99984C4.16602 10.3268 5.52856 12.3356 7.49935 13.2718V13.8332C7.49935 14.6097 7.49935 14.998 7.62622 15.3043C7.79537 15.7127 8.11983 16.0371 8.52821 16.2063C8.83449 16.3332 9.22278 16.3332 9.99935 16.3332C10.7759 16.3332 11.1642 16.3332 11.4705 16.2063C11.8789 16.0371 12.2033 15.7127 12.3725 15.3043C12.4993 14.998 12.4993 14.6097 12.4993 13.8332V13.2718Z" 
            stroke={color} 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
        />
    </svg>
);

export default Lightbulb;