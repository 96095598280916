import { makeObservable, action, observable } from "mobx";
import API from "util/API";

class AtlasStore {
  public dialData: any = { events: [], locations: [] };

  constructor() {
    makeObservable(this, {
      dialData: observable,

      loadTileData: action,
      loadTileDataSuccess: action,
      loadTileDataFailure: action,

      loadDialData: action,
      loadDialDataSuccess: action,
      loadDialDataFailure: action,
    });
  }

  loadTileDataSuccess = () => {};

  loadTileDataFailure = () => {};

  loadTileData = (data: any) => {
    return new Promise((resolve, reject) => {
      API.post("/api/query", data)
        .then(response => response.json())
        .then(data => {
          this.loadTileDataSuccess();
          return resolve(data);
        })
        .catch((error) => {
          this.loadTileDataFailure();
          return reject(error);
        });
    });
  };

  loadDialDataSuccess = (data: any) => {
    this.dialData = data;
  };

  loadDialDataFailure = () => {};

  loadDialData = (data: any) => {
    return new Promise((resolve, reject) => {
      API.post("/api/query", data)
        .then(response => response.json())
        .then(data => {
          this.loadDialDataSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.loadDialDataFailure();
          return reject(error);
        });
    });
  };
}

const atlasStore = new AtlasStore();

export default atlasStore;
