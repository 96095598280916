import { makeObservable, action, observable } from "mobx";
import { toast } from "react-toastify";
import PromptStore from "./PromptStore";

import API, { API_ERRORS } from "util/API";

export type PostNLQueryResponse = {
    responseString: string;
    errorString: string;
    mappedQuery: {
        category: string;
        aspects: string[];
        constraints: Record<string, unknown>;
        aggregation: string;
        aggregation_aspects: string[] | null;
    };
};

class NLPStore {
    public queryId: string = undefined;
    public isCurrentTab: boolean = false;
    public rawPrompt: string = null;
    public loading: boolean = false;
    public response: PostNLQueryResponse = null;

    public parseRequestController: AbortController = null;

    public submittedWrong: boolean = false;
    public submittedParsing: boolean = false;

    constructor() {
        makeObservable(this, {
            isCurrentTab: observable,
            loading: observable,
            rawPrompt: observable,

            submittedParsing: observable,
            submittedWrong: observable,

            setRawPrompt: action,
            setCurrentTab: action,

            parseInput: action,
            parseInputSuccess: action,
            parseInputFailure: action,

            clearFeedback: action,
            giveFeedback: action,
            giveFeedbackFailure: action,
            giveFeedbackSuccess: action,
        });
    }

    setCurrentTab = (isCurrent = false) => {
        this.isCurrentTab = isCurrent;
    }

    parseInputSuccess = (data: any) => {
        this.response = data;
        this.loading = false;
        this.parseRequestController = null;

        if (data?.mappedQuery?.category === 'not_query') { 
            PromptStore.updateMessageById(this.queryId, { loading: false, type: 'unsupported' });
        } else {
            PromptStore.updateMessageById(this.queryId, { 
                loading: false, 
                type: 'query_response', 
                content: data.responseString 
            });
        }
    };

    parseInputFailure = (reason) => {
        this.loading = false;
        this.parseRequestController = null;

        if (reason === API_ERRORS.aborted) {
            return;
        }

        PromptStore.updateMessageById(this.queryId, { 
            loading: false, 
            type: reason === API_ERRORS.timedOut ? 'timeout' : 'error',
            content: ''
        });
    };

    parseInput = () => {
        this.loading = true;
        this.response = null;
        this.clearFeedback();
        this.queryId = Date.now().toString();

        if (this.parseRequestController) {
            this.parseRequestController.abort(API_ERRORS.aborted);
        }

        this.parseRequestController = new AbortController();

        PromptStore.clearMessages();
        PromptStore.addMessageFromMe({ content: this.rawPrompt, jobId: `${this.queryId}-prompt` });
        PromptStore.addMessageFromAdapter({ loading: true, type: 'comprehension', jobId: this.queryId });

        return new Promise((resolve, reject) => {
            API.post("/api/query/nlp", { prompt: this.rawPrompt }, false, this.parseRequestController.signal)
                .then(response => response.json())
                .then((data: PostNLQueryResponse) => {
                    this.parseInputSuccess(data);
                    return resolve(data);
                })
                .catch((error) => {
                    this.parseInputFailure(error);
                    return error !== API_ERRORS.aborted && reject(error);
                });
        });
    };

    setRawPrompt = (prompt: string) => {
        this.rawPrompt = prompt;

        if (this.parseRequestController) {
            this.parseRequestController.abort(API_ERRORS.aborted);
            this.parseRequestController = null;
        }

        if (prompt !== '' && prompt !== null) {
            // @ts-ignore
            document.querySelector('.intention-inputbar textarea')?.focus();
            //debounceUserInput();
        }
    }

    clearResponse = () => {
        this.response = null;

        if (this.parseRequestController) {
            this.parseRequestController.abort(API_ERRORS.aborted);
            this.parseRequestController = null;
        }
    };

    clearFeedback = () => {
        this.submittedParsing = false;
        this.submittedWrong = false;
    }

    giveFeedbackSuccess = () => {
        toast.success("Thank you for contributing to the improvement of the experience!", { theme: 'dark', position: 'bottom-right', icon: false });
    }

    giveFeedbackFailure = () => {
        toast.error("There was a problem submitting your feedback. Please try again.", { theme: 'dark', position: 'bottom-right' });
    }

    giveFeedback = (feedback: string) => {
        if (!this.response) {
            toast.error("No response available to give feedback on.", { theme: 'dark', position: 'bottom-right' });
            return;
        }

        switch(feedback) {
            case 'UserFeedbackWrongResults':
                this.submittedWrong = true;
                break;

            case 'UserFeedbackIncorrectQueryParsing':
                this.submittedParsing = true;
                break;
        }

        API.post("/api/query/nl2queryfeedback", {
            feedback,
            originalQuery: this.rawPrompt,
            response: this.response
        }, false)
            .then(this.giveFeedbackSuccess)
            .catch(this.giveFeedbackFailure);
    }
}

const store = new NLPStore();

export default store;

function debounce(func, timeout = 1000) {
    let timer;

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

const debounceUserInput = debounce(() => store.parseInput());