import React from 'react';
import humanizeDuration from 'humanize-duration';

import { formatDate_ShortHuman, formatTime_AmPm } from 'util/Time';
import Button from 'components/common/Button';
import { PlaneIcon } from 'components/common/Icon';
import Pill from 'components/common/Pill';
import FlightOptionUtilityBar from './FlightOptionUtilityBar';

import './FlightOption.scss';
import { NumberValue } from 'd3';

export type OneWayFlight = {
    airline_logo: string[];
    airlines: string[];
    airplanes: string[];
    arrival_airport: string;
    arrival_time: string;
    booking_link: string;
    booking_url?: string;
    departure_airport: string;
    departure_time: string;
    durations: number[];
    total_duration: number;
    flight_numbers: string[];
    layovers: any[];
    legroom: string[];
    overnight: boolean[];
    price: number;
    serp_booking_token: string;
    travel_class: string[];
    utility_costs?: UtilityCosts;
};

export type FlightLegOption = {
    arrival_airport_name?: string;
    arrival_time: string;
    arrival_date: string;
    arrival_airport: string;
    departure_date: string;
    airplane?: string;
    flight_number: string;
    travel_class:string;
    duration_minutes: number;
    airline: string;
    departure_airport_name:string;
    departure_airport: string;
    departure_time: string;
};

export type RoundTripFlight = {
    booking_url: string;
    departure_option: OneWayFlight;
    return_option: OneWayFlight;
    utility_costs?: UtilityCosts;
};

export type MultiCityFlight = {
    price: number;
    booking_url: string;
    legs: FlightLegOption[];

};

export type UtilityCosts = {
    time_cost_before: number;
    time_cost_after: number;
    stops_preference_cost: number;
    total_costs: number;
    time_cost: number;
};

type FlightOptionProps = {
    option: OneWayFlight | RoundTripFlight| MultiCityFlight;
    index: number;
    allOptions: (OneWayFlight | RoundTripFlight | MultiCityFlight)[];
};

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3
});

class FlightOption extends React.Component<FlightOptionProps, {}> {
    isRoundTrip = () => {
        const { option } = this.props;
        return 'departure_option' in option;
    }

    isMultiCity = () => {
        const { option } = this.props;
        return 'legs' in option;
    }

    getFlightType = () => {
        if (this.isRoundTrip()) return 'round trip';
        if (this.isMultiCity()) return 'multi-city';
        return 'one way';
    }

    getPrice = () => {
        const { option } = this.props;

        if (this.isRoundTrip()) {
            if ((option as RoundTripFlight).departure_option && (option as RoundTripFlight).departure_option.price) {
                const price = (option as RoundTripFlight).departure_option.price;
                return USDollar.format(price);
            }
        } else if (this.isMultiCity()) {
            if ((option as MultiCityFlight).price) {
                return USDollar.format((option as MultiCityFlight).price);
            }
        } else if ((option as OneWayFlight).price) {
            return USDollar.format((option as OneWayFlight).price);
        }

        return 'Price not available';
    }

    sortLegs = (legs: FlightLegOption[]): FlightLegOption[] => {
        return legs.sort((a, b) => {
            const dateA = new Date(`${a.departure_date}T${a.departure_time}`);
            const dateB = new Date(`${b.departure_date}T${b.departure_time}`);
            return dateA.getTime() - dateB.getTime();
        });
    }

    renderFlightLeg = (leg: OneWayFlight | FlightLegOption, index: number) => {
        if (!leg) return <></>;

        const isOneWayOrRoundTrip = 'total_duration' in leg;

        const departureTime = isOneWayOrRoundTrip ? leg.departure_time : leg.departure_date + 'T' + leg.departure_time;
        const arrivalTime = isOneWayOrRoundTrip ? leg.arrival_time : leg.arrival_date + 'T' + leg.arrival_time;
        const duration = isOneWayOrRoundTrip ? leg.total_duration : leg.duration_minutes;
        const airline = isOneWayOrRoundTrip ? leg.airlines[0] : leg.airline;
        const airlineLogo = isOneWayOrRoundTrip ? leg.airline_logo[0] : undefined; // Assuming multi-city doesn't have logos

        return (
            <div key={index} className="option-details">
                <div className="option-details-title-wrapper">
                    <div>
                        {airlineLogo
                            ? <img src={airlineLogo} alt={airline} className="airline-logo" />
                            : <PlaneIcon />
                        }
                    </div>
                    <div>{formatDate_ShortHuman(departureTime)}</div>
                    <div>{airline}</div>
                </div>

                <div className="option-details-body-wrapper">
                    <div>
                        {formatTime_AmPm(departureTime)}<br />
                        {isOneWayOrRoundTrip ? leg.departure_airport : leg.departure_airport_name}
                    </div>

                    <div className="duration">
                        <div className="visual"></div>
                        <div>
                            {humanizeDuration(duration * 60 * 1000)}
                        </div>
                        <div className="layovers">
                            {isOneWayOrRoundTrip && leg.layovers
                                ? `${leg.layovers.length} stop(s): ${leg.layovers.join(', ')}`
                                : 'nonstop'
                            }
                        </div>
                    </div>

                    <div>
                        {formatTime_AmPm(arrivalTime)}<br />
                        {isOneWayOrRoundTrip ? leg.arrival_airport : leg.arrival_airport_name}
                    </div>
                </div>
            </div>
        );
    }

    renderFlightDetails = () => {
        const { option } = this.props;

        if (this.isRoundTrip()) {
            const roundTripOption = option as RoundTripFlight;
            return (
                <>
                    {this.renderFlightLeg(roundTripOption.departure_option, 0)}
                    {this.renderFlightLeg(roundTripOption.return_option, 1)}
                </>
            );
        } else if (this.isMultiCity()) {
            const multiCityOption = option as MultiCityFlight;
            const sortedLegs = this.sortLegs(multiCityOption.legs);
            return sortedLegs.map((leg, index) => this.renderFlightLeg(leg, index));
        } else {
            return this.renderFlightLeg(option as OneWayFlight, 0);
        }
    }

    render() {
        const { option, index, allOptions } = this.props;
        const isTopPick = index === 0;

        return (
            <div className="adapter-intention-option">
                <div className="option-price-header">
                    <div className="option-price-header-wrapper">
                        <h3>{this.getPrice()}</h3>
                        <p>{this.getFlightType()}</p>
                    </div>
                    {isTopPick && <Pill highlighted>Best Match</Pill>}
                </div>

                {this.renderFlightDetails()}

                <FlightOptionUtilityBar option={option} allOptions={allOptions} />

                {(option as any).booking_url && (
                    <Button
                        size="2xl"
                        onClick={() => window.open((option as any).booking_url, '_blank')}
                        style={{ width: '100%', marginTop: '24px' }}
                    >Book Now</Button>
                )}
            </div>
        );
    }
}

export default FlightOption;