export class Event {
    name?: string;
    location?: string;

    constructor(data: Partial<Event> = {}) {
        Object.assign(this, data);
    }

    static fromJSON(json: any): Event {
        return new Event(json);
    }

    toJSON(): object {
        return {
            name: this.name,
            location: this.location,
        };
    }
}

export class TimePredicate {
    pivot: 'before' | 'by' | 'after';
    time: string;  // 'YYYY-MM-DD HH:MM' 24hr format
    event?: Event;

    constructor(data: Partial<TimePredicate>) {
        Object.assign(this, data);
        if (data.event) {
            this.event = new Event(data.event);
        }
    }

    static fromJSON(json: any): TimePredicate {
        return new TimePredicate({
            ...json,
            event: json.event ? Event.fromJSON(json.event) : undefined,
        });
    }

    toJSON(): object {
        return {
            pivot: this.pivot,
            time: this.time,
            event: this.event?.toJSON(),
        };
    }
}

export class DistancePredicate {
    dimension: 'travel_time' | 'distance';
    value: number;
    unit: 'miles' | 'minutes' | 'hrs' | 'km';
    method: 'car' | 'cycling' | 'foot';

    constructor(data: Partial<DistancePredicate>) {
        Object.assign(this, data);
    }

    static fromJSON(json: any): DistancePredicate {
        return new DistancePredicate(json);
    }

    toJSON(): object {
        return {
            dimension: this.dimension,
            value: this.value,
            unit: this.unit,
            method: this.method,
        };
    }
}

export class GeographicPredicate {
    pivot: 'in' | 'nearby';
    name?: string;
    address?: string;

    constructor(data: Partial<GeographicPredicate>) {
        Object.assign(this, data);
    }

    static fromJSON(json: any): GeographicPredicate {
        return new GeographicPredicate(json);
    }

    toJSON(): object {
        return {
            pivot: this.pivot,
            name: this.name,
            address: this.address,
        };
    }
}

export class LegPredicate {
    type: 'departure' | 'return';
    start?: TimePredicate;
    end?: TimePredicate;

    constructor(data: Partial<LegPredicate>) {
        Object.assign(this, data);
        if (data.start) {
            this.start = new TimePredicate(data.start);
        }
        if (data.end) {
            this.end = new TimePredicate(data.end);
        }
    }

    static fromJSON(json: any): LegPredicate {
        return new LegPredicate({
            ...json,
            start: json.start ? TimePredicate.fromJSON(json.start) : undefined,
            end: json.end ? TimePredicate.fromJSON(json.end) : undefined,
        });
    }

    toJSON(): object {
        return {
            type: this.type,
            start: this.start?.toJSON(),
            end: this.end?.toJSON(),
        };
    }
}

export default class Constraints {
    start?: TimePredicate;
    end?: TimePredicate;
    legs?: LegPredicate[];
    distance?: DistancePredicate;
    geographic?: GeographicPredicate;

    constructor(data: Partial<Constraints> = {}) {
        Object.assign(this, data);
        if (data?.start) {
            this.start = new TimePredicate(data.start);
        }
        if (data?.end) {
            this.end = new TimePredicate(data.end);
        }
        if (data?.legs) {
            this.legs = data.legs.map(leg => new LegPredicate(leg));
        }
        if (data?.distance) {
            this.distance = new DistancePredicate(data.distance);
        }
        if (data?.geographic) {
            this.geographic = new GeographicPredicate(data.geographic);
        }
    }

    static fromJSON(json: any): Constraints {
        return new Constraints({
            start: json.start ? TimePredicate.fromJSON(json.start) : undefined,
            end: json.end ? TimePredicate.fromJSON(json.end) : undefined,
            legs: json.legs ? json.legs.map(LegPredicate.fromJSON) : undefined,
            distance: json.distance ? DistancePredicate.fromJSON(json.distance) : undefined,
            geographic: json.geographic ? GeographicPredicate.fromJSON(json.geographic) : undefined,
        });
    }

    toJSON(): object {
        return {
            start: this.start?.toJSON(),
            end: this.end?.toJSON(),
            legs: this.legs?.map(leg => leg.toJSON()),
            distance: this.distance?.toJSON(),
            geographic: this.geographic?.toJSON(),
        };
    }
}