import { ComprehensionResponse } from "..";
import Constraints from "../constraints";
import { ResponseBase, ComprehensionView } from "./base";

type TripType = "oneway" | "roundtrip" | "multicity";
type CabinClass = "economy" | "premium_economy" | "business" | "first";

interface FlightLeg {
    departure_city?: string;
    departure_airport?: string[];
    destination_city?: string;
    destination_airport?: string[];
    departure_date: string;
    departure_time?: string;
    arrival_date?: string;
    arrival_time?: string;
}

export class FlightResponse extends ResponseBase {
    query: string;
    trip_type: TripType;
    legs: FlightLeg[];
    num_adults: number;
    max_num_stops?: number;
    num_children?: number;
    cabin_class?: CabinClass;
    notepad?: string;

    constructor(data: Partial<FlightResponse>, comprehensionResponse: ComprehensionResponse) {
        super(comprehensionResponse);
        Object.assign(this, data);
    }

    private convertTo12HourFormat(time: string): string {
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours, 10);
        const ampm = hour >= 12 ? 'pm' : 'am';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minutes} ${ampm}`;
    }

    private formatEventInfo(event: { name?: string; location?: string }, time: string): string {
        const formattedTime = this.convertTo12HourFormat(time.split(' ')[1]);
        return `Event: ${event.name || 'Detected'}${event.location ? ` at ${event.location}` : ''} at ${formattedTime}`;
    }

    private formatLegInfo(leg: any, type: 'start' | 'end'): string {
        if (leg[type]?.event) {
            return this.formatEventInfo(leg[type].event, leg[type].time);
        } else if (leg[type]) {
            const action = type === 'start' ? 'Departing' : 'Arriving';
            const formattedTime = this.convertTo12HourFormat(leg[type].time.split(' ')[1]);
            return `${action} ${leg[type].pivot} ${formattedTime}`;
        }
        return '';
    }

    private formatLocation(city?: string, airports?: string[]): string {
        if (airports && airports.length > 0) {
            return airports.join('/');
        }
        return city || 'Unknown location';
    }

    private formatLeg(leg: FlightLeg, index: number): string[] {
        const legInfo = [];
        legInfo.push(`From: ${this.formatLocation(leg.departure_city, leg.departure_airport)}`);
        legInfo.push(`To: ${this.formatLocation(leg.destination_city, leg.destination_airport)}`);
        legInfo.push(`Date: ${leg.departure_date}`);
        if (leg.departure_time) {
            legInfo.push(`Departure: ${this.convertTo12HourFormat(leg.departure_time)}`);
        }
        if (leg.arrival_date && leg.arrival_date !== leg.departure_date) {
            legInfo.push(`Arrival Date: ${leg.arrival_date}`);
        }
        if (leg.arrival_time) {
            legInfo.push(`Arrival: ${this.convertTo12HourFormat(leg.arrival_time)}`);
        }
        return legInfo;
    }
    render(constraints?: Constraints): ComprehensionView {
        const type = [this.trip_type.charAt(0).toUpperCase() + this.trip_type.slice(1)];
    
        if (this.max_num_stops !== undefined) {
            type.push(this.max_num_stops === 0 ? 'Non-Stop' : this.max_num_stops === 1 ? 'Layover OK' : 'Layovers OK');
        }
        if (this.cabin_class) {
            type.push(this.cabin_class
                .replace(/_/g, ' ')
                .replace(/^\w/, c => c.toUpperCase()));
        }
    
        const attrs: Record<string, string[]> = {
            Type: type,
            Passengers: [
                ...(this.num_adults > 1 ? [`${this.num_adults} Adults`] : [`${this.num_adults} Adult`]),
                ...(this.num_children ? [`${this.num_children} Children`] : [])
            ]
        };
    
        if ((this.trip_type === 'oneway' || this.trip_type === 'roundtrip') && this.legs.length > 0) {
            const departure = [
                ...this.formatLocation(this.legs[0].departure_city, this.legs[0].departure_airport).split('/'),
                this.legs[0].departure_date,
                ...(this.legs[0].departure_time ? [this.convertTo12HourFormat(this.legs[0].departure_time)] : [])
            ];
            const arrival = [
                ...this.formatLocation(this.legs[0].destination_city, this.legs[0].destination_airport).split('/'),
                ...(this.legs[0].arrival_date && this.legs[0].arrival_date !== this.legs[0].departure_date ? [this.legs[0].arrival_date] : []),
                ...(this.legs[0].arrival_time ? [this.convertTo12HourFormat(this.legs[0].arrival_time)] : [])
            ];
    
            attrs.Departure = departure;
            attrs.Arrival = arrival;
    
            if (this.trip_type === 'roundtrip' && this.legs[1]) {
                attrs.Return = [
                    this.legs[1].departure_date,
                    ...(this.legs[1].departure_time ? [this.convertTo12HourFormat(this.legs[1].departure_time)] : [])
                ];
            }
    
            if (constraints?.legs) {
                constraints.legs.forEach(leg => {
                    if (leg.type === 'departure') {
                        const endInfo = this.formatLegInfo(leg, 'end');
                        if (endInfo) attrs.Arrival.push(endInfo);
                        const startInfo = this.formatLegInfo(leg, 'start');
                        if (startInfo) attrs.Departure.push(startInfo);
                    } else if (leg.type === 'return' && this.trip_type === 'roundtrip') {
                        if (!attrs.Return) attrs.Return = [];
                        const startInfo = this.formatLegInfo(leg, 'start');
                        if (startInfo) attrs.Return.push(startInfo);
                        const endInfo = this.formatLegInfo(leg, 'end');
                        if (endInfo) attrs.Return.push(endInfo);
                    }
                });
            }
        } else {
            // Multicity
            this.legs.forEach((leg, index) => {
                attrs[`Leg ${index + 1}`] = this.formatLeg(leg,index);
            });
    
            if (constraints?.legs) {
                constraints.legs.forEach((leg, index) => {
                    const legKey = `Leg ${index + 1}`;
                    if (!attrs[legKey]) attrs[legKey] = [];
                    const startInfo = this.formatLegInfo(leg, 'start');
                    if (startInfo) attrs[legKey].push(startInfo);
                    const endInfo = this.formatLegInfo(leg, 'end');
                    if (endInfo) attrs[legKey].push(endInfo);
                });
            }
        }
    
        return { attrs };
    }
}
