import React, { ChangeEvent, FormEvent } from 'react';
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";

import StepHeader from './StepHeader';
import Card from 'components/common/Card';
import BaseForm from 'components/forms/BaseForm';
import OnboardingWrapper from '../index';
import VerifyPhoneModal from '../modals/VerifyPhoneModal';
import Loading from 'components/common/Loading';

type AccountDetailsProps = {
    UserStore?: any;
};

type AccountDetailsState = {
    first_name: string | undefined;
    last_name: string | undefined;
    phone: string | undefined;
    email: string | undefined;
    homeAddress: string | undefined;
    dateOfBirth: string | undefined;
    showModal: boolean;
    isLoading: boolean;
}

class AccountDetails extends React.Component<AccountDetailsProps, AccountDetailsState> {
    state = {
        first_name: undefined,
        last_name: undefined,
        phone: undefined,
        email: undefined,
        homeAddress: undefined,
        dateOfBirth: undefined,
        showModal: false,
        isLoading: false
    };

    componentDidMount(): void {
        const { UserStore } = this.props;

        // user metadata will tell us if they need to complete onboarding
        // if that data isn't present, kick the user out
        if (!UserStore.isOnboarding) {
            window.location.href = '/dashboard';
            return;
        }

        this.setState({
            first_name: UserStore.user.first_name || undefined,
            last_name: UserStore.user.last_name || undefined,
            phone: UserStore.user.phone || undefined,
            email: UserStore.user.email || undefined,
            homeAddress: UserStore.user.metadata.homeAddress || undefined,
            dateOfBirth: UserStore.user.metadata.dateOfBirth || undefined
        });
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        // @ts-ignore
        this.setState({ [name]: value });
    };

    updateUser = () => {
        const { UserStore } = this.props;
        const { email, first_name, last_name, phone, homeAddress, dateOfBirth } = this.state;

        UserStore.updateUser({
            // @ts-ignore
            email: email.toLowerCase(),
            first_name,
            last_name,
            phone,
            metadata: { homeAddress, dateOfBirth }
        })
            .then(this.requestPasscode)
            .catch(() => {
                this.setState({ isLoading: false }, () => {
                    toast.error("An error occurred while trying to save your account details.", { icon: false });
                });
            });
    }

    requestPasscode = () => {
        const { UserStore } = this.props;
        const { phone } = this.state;

        UserStore.requestPhoneVerification(phone)
            .then(() => {
                this.setState({ showModal: true, isLoading: false });
            })
            .catch(() => {
                this.setState({ isLoading: false }, () => {
                    toast.error("An error occurred while trying to send a verification code to your mobile phone.", { icon: false });
                });
            })
    }

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.setState({ isLoading: true }, this.updateUser);
    };

    render() {
        const { UserStore } = this.props;
        const { email, phone, first_name, last_name, homeAddress, dateOfBirth, showModal, isLoading } = this.state;

        const config = [
            {
                labelText: "Email Address",
                fieldId: "email",
                type: "email",
                value: email,
                disabled: true
            },
            {
                labelText: "First Name",
                fieldId: "first_name",
                type: "text",
                value: first_name,
                required: true,
                errorText: first_name !== undefined && first_name?.length === 0 ? "First name is required" : undefined
            },
            {
                labelText: "Last Name",
                fieldId: "last_name",
                type: "text",
                value: last_name,
                required: true,
                errorText: last_name !== undefined && last_name?.length === 0 ? "Last name is required" : undefined
            },
            {
                labelText: "Mobile Phone",
                fieldId: "phone",
                type: "text",
                value: phone,
                required: true,
                errorText: phone !== undefined && phone?.length === 0 ? "Mobile phone number is required" : undefined
            },
            {
                labelText: "Home Address",
                fieldId: "homeAddress",
                type: "address",
                value: homeAddress,
                required: true,
                errorText: homeAddress !== undefined && homeAddress?.length === 0 ? "Home address is required" : undefined
            },
            {
                labelText: "Birthday",
                fieldId: "dateOfBirth",
                type: "date",
                value: dateOfBirth,
                required: true,
                errorText: dateOfBirth !== undefined && dateOfBirth?.length === 0 ? "DOB is required" : undefined
            },
        ];

        return (
            <>
                <StepHeader currentStep={2} />
                {showModal && <VerifyPhoneModal onClose={() => this.setState({ showModal: false, isLoading: false })} />}
                {isLoading && <Loading />}

                <div className="adapter-two-col">

                    <div className="adapter-two-col--title">
                        <div className="flex-1"></div>

                        <h1>
                            Account Details
                        </h1>

                        <p>
                            The following details will help Adapter get to know you better.
                        </p>

                        <p>
                            Providing your mobile phone number will allow Adapter to send you texts for your prompt results.
                        </p>

                        <div className="flex-1"></div>
                    </div>

                    <div className="adapter-two-col--content">
                        <div className="flex-1"></div>

                        <Card>
                            <BaseForm
                                config={config}
                                isLoading={UserStore.isLoading}
                                buttonText="Continue"
                                onChange={this.onChangeHandler}
                                onSubmit={this.onSubmitHandler}
                                style={{ minWidth: '50%' }}
                            />
                        </Card>

                        <div className="flex-1"></div>
                    </div>
                </div>
            </>
        );
    }
}

const AccountDetailsWithStores = inject("UserStore")(observer(AccountDetails));

const Wrapper = () => (
    <OnboardingWrapper>
        <AccountDetailsWithStores />
    </OnboardingWrapper>
);

export default Wrapper