import React from "react";

import Modal from "components/modal/Modal";
import AccountDisplay from "./AccountDisplay";

import "./ConnectAccountPopup.scss";

type ConnectAccountPopupProps = {
    UserStore?: any;
    onClose: () => void;
};

class ConnectAccountPopup extends React.Component<ConnectAccountPopupProps, any> {
    render() {
        const { onClose } = this.props;
        
        return (
            <Modal onClose={onClose}>
                <h3>Connect an account</h3>

                <p>Connect any Google account by filling the information below.</p>

                <AccountDisplay />
            </Modal>
        );
    }
}

export default ConnectAccountPopup;