import React from 'react';

const StepHeader = ({ currentStep = 0 }) => {
    const assignClassname = (stepNumber) => {
        if (stepNumber > currentStep) {
            return 'incompleteStep';
        } else if (currentStep === stepNumber) {
            return 'activeStep';
        }

        return 'completedStep';
    }

    return (
        <div className="adapter-onboarding-step-header">
            <ol>
                <li className={assignClassname(1)}><p>Our Promise</p></li>
                <li className={assignClassname(2)}><p>Account Details</p></li>
                <li className={assignClassname(3)}><p>Connect Accounts</p></li>
                <li className={assignClassname(4)}><p>Data Controls</p></li>
                <li className={assignClassname(5)}><p>Ingestion</p></li>
            </ol>
        </div>
    );
};

export default StepHeader;