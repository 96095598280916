import React from 'react';

const Globe = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 80 81" fill="none">
        <path
            d="M39.5592 6.91602C49.4342 13.5827 52.4716 27.8895 52.7259 40.2493C52.4716 52.6092 49.4343 66.916 39.5593 73.5827M39.5592 6.91602C29.6842 13.5827 26.6469 27.8895 26.3926 40.2494C26.6469 52.6092 29.6843 66.916 39.5593 73.5827M39.5592 6.91602C21.3799 6.91602 6.64258 21.8399 6.64258 40.2493M39.5592 6.91602C57.7386 6.91602 72.4759 21.8399 72.4759 40.2493M39.5593 73.5827C57.7386 73.5827 72.4759 58.6588 72.4759 40.2493M39.5593 73.5827C21.3799 73.5827 6.64258 58.6588 6.64258 40.2493M72.4759 40.2493C65.8926 50.2493 51.7646 53.3252 39.5592 53.5827C27.3539 53.3252 13.2259 50.2494 6.64258 40.2493M72.4759 40.2493C65.8926 30.2493 51.7646 27.1735 39.5592 26.916C27.3539 27.1735 13.2259 30.2494 6.64258 40.2493"
            strokeWidth="3"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>

);

export default Globe;