import React from 'react';

import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { AlertCircleIcon, CloseIcon } from 'components/common/Icon';

const DataHandlingModal = ({ onClose }) => (
    <Modal>
        <div className="onboarding-modal">
            <div className="onboarding-modal-head">
                <AlertCircleIcon color="#5C33CC" />
                <CloseIcon
                    onClick={onClose}
                    style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }}
                />
            </div>

            <div className="onboarding-modal-body">
                <h3>How Adapter Handles Your Data</h3>

                <p>
                    When you connect a 3rd party account to Adapter, you are giving Adapter permission to access a 
                    scoped set of data from that product. That scope is defined by each product in its connection flow. 
                    Adapter retrieves this historical user data as well as any new pieces of data when they are created.
                </p>

                <p>
                    When Adapter retrieves your data it filters it and creates an encrypted copy of any relevant pieces of 
                    data on Adapter&quot;s hosted servers. Adapter then analyzes this data to identify data points useful 
                    to understanding your patterns and preferences. This includes information like flights, hotel stays, 
                    expenses and more.
                </p>

                <p>
                    Adapter gives users the ability to revoke access to 3rd party accounts at any time. When a user 
                    disconnects an account, Adapter will no longer be able to retrieve data from this account. This includes 
                    both new and historical data. Encrypted copies of data from this account, stored within Adapter, will not 
                    be deleted and this data can be re-analyzed by Adapter in the future to extract additional data points. 
                    Information gathered from processing 3rd party data or generated by a user when interacting with this data 
                    through Adapter&quot;s product will also not be deleted. Once data is connected to Adapter, it 
                    cannot be selectively &quot;forgotten&quot;.
                </p>

                <p>
                    Your information in Adapter is encrypted and safely guarded. Access to Adapter&quot;s data systems is strictly 
                    controlled and restricted to Adapter employees for incident-response purposes only. Any access granted is always 
                    limited, time bound, and audited. Adapter uses programmatic and cryptographic systems in place that prevent any 
                    unauthorized internal or external access to your data. No one is allowed to access the encrypted copies of your 
                    imported data.
                </p>

                <p>
                    You may request that all of your data and account be deleted by contacting customer support at any time.
                </p>
            </div>

            <div className="onboarding-modal-foot">
                <Button onClick={onClose}>Close</Button>
            </div>
        </div>
    </Modal>
);

export default DataHandlingModal;