import Constraints from "../constraints";
import { ResponseBase, ComprehensionView, LiteralType } from "./base";

export class UnsupportedResponse extends ResponseBase {
    action: LiteralType<"create" | "read" | "update" | "delete">;
    response?: string;

    constructor(data: Partial<UnsupportedResponse>) {
        super();
        Object.assign(this, data);
    }


    render(constraints?: Constraints): ComprehensionView {
        return {
            text: this.response
        }
    }
}