import React from 'react';

const LinkBroken = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path 
            d="M9 16L16 8.99998M9.5 4.5V2.5M15.5 20.5V22.5M4.5 9.5H2.5M20.5 15.5H22.5M5.41421 5.41421L4 4M19.5858 19.5857L21 20.9999M12.5 18.1568L10.3787 20.2781C8.81662 21.8402 6.28396 21.8402 4.72186 20.2781C3.15976 18.716 3.15976 16.1833 4.72186 14.6212L6.84318 12.4999M18.1569 12.4999L20.2782 10.3786C21.8403 8.81649 21.8403 6.28383 20.2782 4.72174C18.7161 3.15964 16.1835 3.15964 14.6214 4.72174L12.5 6.84306"
            stroke={color} 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </svg>
);

export default LinkBroken;