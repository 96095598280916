import React from 'react';

const Target = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 80 81" fill="none">
        <path
            d="M53.3335 26.916V16.916L63.3335 6.91602L66.6669 13.5827L73.3335 16.916L63.3335 26.916H53.3335ZM53.3335 26.916L40.0002 40.2492M73.3337 40.2494C73.3337 58.6588 58.4098 73.5827 40.0003 73.5827C21.5908 73.5827 6.66699 58.6588 6.66699 40.2494C6.66699 21.8399 21.5908 6.91602 40.0003 6.91602M56.667 40.2494C56.667 49.4541 49.2051 56.916 40.0003 56.916C30.7956 56.916 23.3337 49.4541 23.3337 40.2494C23.3337 31.0446 30.7956 23.5827 40.0003 23.5827" 
            strokeWidth="3"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Target;