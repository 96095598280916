import React from 'react';

const Announcement = ({ color }: { color: string }) => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="announcement-03">
            <path 
                id="Icon" 
                d="M15.9167 13.3333C17.5276 13.3333 18.8334 10.9082 18.8334 7.91667C18.8334 4.92512 17.5276 2.5 15.9167 2.5M15.9167 13.3333C14.3059 13.3333 13.0001 10.9082 13.0001 7.91667C13.0001 4.92512 14.3059 2.5 15.9167 2.5M15.9167 13.3333L5.03637 11.3551C4.26346 11.2146 3.877 11.1443 3.56452 10.9908C2.92881 10.6785 2.45538 10.1113 2.26187 9.42994C2.16675 9.09504 2.16675 8.70225 2.16675 7.91667C2.16675 7.13108 2.16675 6.73829 2.26187 6.40339C2.45538 5.72207 2.92881 5.15481 3.56452 4.84254C3.877 4.68904 4.26346 4.61878 5.03637 4.47825L15.9167 2.5M4.66675 11.6667L4.99496 16.2617C5.02613 16.698 5.04172 16.9162 5.13664 17.0816C5.22022 17.2272 5.34582 17.3441 5.49699 17.4171C5.66869 17.5 5.88743 17.5 6.32491 17.5H7.81025C8.31037 17.5 8.56043 17.5 8.74551 17.4003C8.90814 17.3126 9.03703 17.1734 9.11194 17.0046C9.19719 16.8124 9.17801 16.5631 9.13965 16.0644L8.83342 12.0833" 
                stroke={color} 
                strokeWidth="1.66667" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
            />
        </g>
    </svg>

);

export default Announcement;