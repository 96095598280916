import React from 'react';

const QuestionCircle = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            d="M8.24271 7.81834C8.38954 7.40094 8.67936 7.04898 9.06082 6.82479C9.44229 6.6006 9.89079 6.51864 10.3269 6.59345C10.763 6.66825 11.1585 6.89498 11.4435 7.23348C11.7284 7.57198 11.8844 8.0004 11.8837 8.44287C11.8837 9.69194 10.0101 10.3165 10.0101 10.3165M10.0343 12.8165H10.0426M18.3332 9.50008C18.3332 14.1025 14.6022 17.8334 9.99984 17.8334C5.39746 17.8334 1.6665 14.1025 1.6665 9.50008C1.6665 4.89771 5.39746 1.16675 9.99984 1.16675C14.6022 1.16675 18.3332 4.89771 18.3332 9.50008Z"
            strokeWidth="1.666"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default QuestionCircle;