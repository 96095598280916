import React from 'react';
import Notice from 'components/common/Notice';
import { LightbulbIcon } from 'components/common/Icon';

import '../Option.scss';

const UnsupportedOption = ({ }) => {
    return (
        <>
            <Notice text='Unsupported Request' warning />

            <div data-test-id="errors.unsupported">
                Adapter is unable to process this type of request right now.
                If you would like to see this feature added, please let us know using the feedback form.
            </div>

            <div className='adapter-unsupported-option'>
                <h3><LightbulbIcon /> Helpful Tips:</h3>

                <p>Adapter currently only supports requests for:</p>

                <ul>
                    <li>Flight bookings</li>
                    <li>Hotel reservations</li>
                    <li>Restaurant reservations</li>
                </ul>
            </div>
        </>
    );
};

export default UnsupportedOption;