import React from 'react';

import './index.scss';

const HeaderWithButton = ({ content, button }: any) => {
    return (
        <div className="adapter-header-with-button">
            <div className="adapter-header-with-button--content">
                {content}
            </div>
            <div className="adapter-header-with-button--button">
                {button}
            </div>
        </div>
    );
};

export default HeaderWithButton;