import { useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';

import ArrowContainer from "components/delight/ArrowContainer"
import Button from "components/common/Button";

import './LandingPage.scss';

const Auth0Handler = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      // user is already auth'd, no need for them to be on this page
      window.location.href = '/dashboard';
    }
  }, [isAuthenticated]);

  return (
    <main className="adapter-basepage">
      <div className="adapter-basepage-inner">
        <div className="adapter-landing-page">

          <ArrowContainer 
            style={{ 
              background: `linear-gradient(45deg, #53389E 0%, #7F56D9 100%)`,
              height: '100vh'
            }}
          >

            <div className="landing-page--center">
              <div className="landing-page--inner-top">
                <img src="/adapter-logo-vertical-white.svg" alt="Adapter" />
              </div>

              <div className="landing-page--inner-bottom">
                <h1>Achieve more with the  power of knowledge and delegation.</h1>

                <div className="landing-page--inner-bottom-buttons">
                  <Button
                    size="2xl"
                    cls="reverse"
                    onClick={() => loginWithRedirect()}
                  >Login</Button>
                  <Button
                    size="2xl"
                    cls="reverse"
                    onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: "signup", } })}
                  >Signup</Button>
                </div>
              </div>
            </div>

          </ArrowContainer>

        </div>
      </div>
    </main>
  );
}

export default Auth0Handler;