import React from "react";
import { AiOutlineClose } from "react-icons/ai";

import "./Modal.scss";

export default class Modal extends React.Component<any, any> {   
    render() {
        const { children, onClose = () => {} } = this.props;

        return (
            <div id="adapter-modal">
                <div id="adapter-modal-inner">
                    <div className="closeBtn">
                        <AiOutlineClose onClick={onClose} />
                    </div>

                    {children}
                </div>
            </div>
        );
    }
}