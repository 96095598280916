import React from 'react';
import Notice from 'components/common/Notice';

const RequestTimeout = () => {
    return (
        <>
            <Notice text='Request timed out' error />

            <div>
                It took too long to process your request. Please try again. 
                If the problem continues, please reach out to customer support.
            </div>
        </>
    );
};

export default RequestTimeout;