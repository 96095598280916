import { jwtDecode } from "jwt-decode";

const refreshWindow = 1000 * 60 * 1; // ms * s * min === 1 minute

export const jwtNeedsRefresh = (token: string = '') => {
    try {
        const decoded = jwtDecode(token);
        const { exp } = decoded;
        
        const now = Date.now();
        // @ts-ignore
        const expirationTime = exp * 1000;

        // @ts-ignore
        if (expirationTime - now < refreshWindow) {
            return true;
        }

        return false;
    } catch (e) {
        return true;
    }
};