import React from 'react';

import './Typography.scss';

type Typography_Props = {
    fontWeight?: 'light' | 'medium' | 'bold';
    fontStyle?: 'regular' | 'italic';
    fontSize?: 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl';
    
    children: any;
    style?: any;
    cls?: string;
}

export const Text = ({ 
    children, 
    fontWeight = 'medium', 
    fontStyle = 'regular', 
    fontSize = 'm',
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <p
            className={`adapter-font-${fontWeight}-${fontStyle} adapter-text-size-${fontSize} ${cls}`}
            style={style}
        >
            {children}
        </p>
    );
}

export const HeadText = ({ 
    children, 
    fontWeight = 'medium', 
    fontStyle = 'regular', 
    fontSize = 'm',
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <h2
            className={`adapter-font-${fontWeight}-${fontStyle} adapter-header-text-size-${fontSize} ${cls}`}
            style={style}
        >
            {children}
        </h2>
    );
}