function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function exponentialBackoff<T>(
  func: () => Promise<T>,
  maxAttempts: number = 5,
  delayInMs: number = 1000,
  maxDelayInMs: number = 30000
): Promise<T> {
  let attempts = 0;

  while (true) {
    try {
      return await func();
    } catch (error) {
      if (attempts === maxAttempts) {
        throw new Error(`failed to resolve within maxAttemps. error: ${error}`);
      }
      // Calculate delay with exponential backoff
      attempts++;
      let waitTime = Math.min(maxDelayInMs, delayInMs * 2 ** attempts);
      await delay(waitTime);
    }
  }
}

export {
  exponentialBackoff,
  delay
};