export const KEYS = {
  leftNavCollapse: 'ADAPTER_leftNavCollapse',
  onboardingState: 'ADAPTER_onboardingState'
};

export const saveToLocalstorage = (key: string, value: any) => {
  if (typeof value === "string") {
    window.localStorage.setItem(key, value);
  } else {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getFromLocalstorage = (key: string) => {
  const value = window.localStorage.getItem(key);

  if (value !== null) {
    let parsedValue = value;
    try {
      parsedValue = JSON.parse(value);
    } catch (e) {
      parsedValue = value;
    }

    return parsedValue;
  }
};

export const deleteFromLocalStorage = (key: string) => {
  window.localStorage.removeItem(key);
};

export const clearLocalstorage = () => {
  window.localStorage.clear();
  clearAllCookies();
};

function clearAllCookies() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const spcook = cookies[i].split("=");
    deleteCookie(spcook[0]);
  }

  function deleteCookie(cookiename) {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    const expires = ";expires=" + d;
    const name = cookiename;

    const value = "";
    document.cookie = name + "=" + value + expires + "; path=/acc/html";
  }
}