import React from 'react';

import './Tab.scss';

const Tab = ({ title = '', url = '/', disabled = false, active = false }) => (
    <div className={`adapter-tabs-tab ${disabled ? 'disabled' : ''} ${active ? 'active' : ''}`}>
        <a href={disabled ? '#' : url}>{title}</a>
    </div>
);

type TabsProps = {
    style?: any;
    tabs: { 
        title: string; 
        url: string; 
        disabled?: boolean;
        active?: boolean;
    }[]
}

class Tabs extends React.Component<TabsProps, any> {
    render() {
        const { tabs, style } = this.props;

        const tabCmps: any[] = [];

        for (let i = 0; i < tabs.length; i++) {
            tabCmps.push(<Tab key={i} {...tabs[i]} />)
        }

        return (
            <div className="adapter-tabs" style={style}>
                {tabCmps}
            </div>
        );
    }
}

export default Tabs;