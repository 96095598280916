import React from 'react';
import classNames from 'classnames';

import { AlertCircleIcon, QuestionCircleIcon } from '../Icon';

import './Notice.scss';

type NoticeProps = {
    text: string;
    warning?: boolean;
    error?: boolean;
};

const Notice = ({ text = '', warning = false, error = false }: NoticeProps) => {
    return (
        <div className={classNames({ 'adapter-notice': true, warning, error })}>
            <div className="adapter-notice-icon">
                {warning && <QuestionCircleIcon />}
                {error && <AlertCircleIcon />}
            </div>

            <div className="adapter-notice-text">
                {text}
            </div>
        </div>
    );
}

export default Notice;