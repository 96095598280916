import React from 'react';

import Card from '../Card';
import './Modal.scss';

const Modal = ({ children }: any) => (
    <div className="adapter-modal">
        <div className="adapter-modal-background" />
        <div className="adapter-modal-content">
            <Card>
                {children}
            </Card>
        </div>
    </div>
);

export default Modal;