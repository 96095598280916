import React from 'react';
import { inject, observer } from 'mobx-react';

import './Intention.scss';

type SuggestionCategory = "flight" | "dining" | "hotel";

export type PromptSuggestion = {
    category: SuggestionCategory;
    request: string;
    short_request: string;
    refs: string[];
}

type IntentionProps = {
    title: string;
    description: string;
    Icon: any;
    prompt: string;
    store?: {
        setRawPrompt: (prompt: string) => void;
    };
}

const Intention = ({ title = '', description = '', prompt = '', Icon, store }: IntentionProps) => (
    <div className="adapter-intention" onClick={() => store.setRawPrompt(prompt)}>
        <Icon color="#73788C" />
        <div className="adapter-intention-content truncate">
            { title && <h3>{title}</h3> }
            <p>{description}</p>
        </div>
    </div>
);

export default Intention;