import React, { ChangeEvent } from "react";
import Autocomplete from "react-google-autocomplete";
import { Link } from "react-router-dom";
import { GOOGLE_MAPS_API_KEY } from "../../../constants";

import './Input.scss';

export type AddressInputProps = {
    labelText?: string | any;
    fieldId: string;

    value?: string | number;
    type?: string; // "text" | "textarea" | "password" | "email";
    placeholder?: string;
    hintText?: string;
    disabled?: boolean;
    required?: boolean;

    errorText?: string;
    error?: boolean;

    selectConfig?: any;

    link?: {
        linkText: string;
        linkUrl: string;
    };

    onChange: (event: ChangeEvent<any>) => void;

    style?: any;
    darkMode?: boolean;
};

export default class AddressInput extends React.Component<AddressInputProps, any> {
    renderTextInput = () => {
        const {
            fieldId,
            value,
            errorText,
            error,
            onChange,
        } = this.props;

        return (
            <Autocomplete
                apiKey={GOOGLE_MAPS_API_KEY}
                onPlaceSelected={({ formatted_address }) => {
                    // @ts-ignore
                    onChange({ 
                        target: { 
                            name: fieldId, 
                            value: formatted_address 
                        } 
                    });
                }}
                id={fieldId}
                className={errorText || error ? 'error' : ''}
                options={{ types: ['address'] }}
                defaultValue={value}
            />
        );
    }

    render() {
        const {
            fieldId,
            hintText,
            errorText,
            labelText,
            link,
            style = {},
            darkMode = false
        } = this.props;

        return (
            <div className={`adapter-form-field-input ${darkMode ? 'darkMode' : ''}`} style={style}>
                {labelText && (
                    <label htmlFor={fieldId}>
                        {labelText}
                    </label>
                )}

                {this.renderTextInput()}

                {link && (
                    <div className="text-sm text-right" style={{ width: '100%' }}>
                        <Link className="font-semibold" to={link.linkUrl}>
                            {link.linkText}
                        </Link>
                    </div>
                )}

                {!errorText && hintText && (
                    <p className="hintText">{hintText}</p>
                )}

                {errorText && (
                    <p className="errorText">{errorText}</p>
                )}
            </div>
        );
    }
}