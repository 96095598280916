import React from "react";
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";

import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from "components/common/BasePage";
import Tabs from "components/common/Tab";
import Button from "components/common/Button";

import "../../brand/index.scss";

interface ResetPasswordPageProps {
  AuthStore?: any;
}

class ResetPasswordPage extends React.Component<ResetPasswordPageProps, any> {
  clickHandler = () => {
    const { AuthStore } = this.props;

    AuthStore.resetPassword()
      .then(() => toast.success('Please check your email'))
      .catch(() => toast.error('An error occurred'));
  }

  render() {
    const tabs = [
      { title: 'Profile', url: '/settings/profile' },
      { title: 'Details', url: '/settings/user-knowledge' },
      { title: 'Password', url: '/settings/password', active: true },
      { title: 'Accounts', url: '/settings/accounts' },
    ];

    return (
      <AuthenticatedPage>
        <BasePage>
          <div className="adapter-fullscreen-page">
            <Tabs tabs={tabs} />

            <div style={{ width: '80vw', maxWidth: '600px', alignSelf: 'center' }}>

              <div className="adapter-card">
                <h3>Reset Password</h3>

                <p>
                  To reset your password, click the button below.
                </p>

                <p>
                  We&apos;ll send you an email with instructions to securely reset your Adapter password.
                </p>

                <Button
                  onClick={this.clickHandler}
                  size="2xl"
                >
                    Send me an email &rarr;
                </Button>
              </div>
            </div>

            <div className="flex-1"></div>
          </div>
        </BasePage>
      </AuthenticatedPage>
    );
  }
}

export default inject("AuthStore")(observer(ResetPasswordPage));
