import React from 'react';

const CaretRight = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M6 12L10 8L6 4"
            stroke={color}
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>
);

export default CaretRight;