import React from 'react';
import { inject, observer } from 'mobx-react';

import NLPInputBar from 'pages/intentions/common/NLPInputBar';
import InputBar from 'pages/intentions/common/InputBar';
import AdapterChat from 'components/common/Chat/AdapterChat';
import PromptToggle from './PromptToggle';
import IntentionList from 'components/common/Intention/IntentionList';

import './PromptFlow.scss';

type PromptFlowProps = {
    NLPStore?: any;
    PromptStore?: any;
}

class PromptFlow extends React.Component<PromptFlowProps, {}> {
    render() {
        const { NLPStore, PromptStore } = this.props;
        const hasPrompted = PromptStore.loadingParseInput || PromptStore.messages.length > 0;

        return (
            <>
                {!hasPrompted && (
                    <div className="adapter-fullscreen-content">
                        <div className="flex-1"></div>

                        <PromptToggle />
                        
                        <IntentionList />
                        
                        { NLPStore.isCurrentTab 
                            ? <NLPInputBar placeholder="Tell me about my data..." />
                            : <InputBar placeholder="Book me a reservation..." />
                        }
                        

                        <div className="flex-1"></div>
                    </div>
                )}

                {hasPrompted && (
                    <AdapterChat />
                )}
            </>
        );
    }
}

export default inject("NLPStore", "PromptStore")(observer(PromptFlow));