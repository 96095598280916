import React, { ChangeEvent } from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from "react-toastify";

import { AnnouncementIcon, CloseIcon, SendIcon } from 'components/common/Icon';
import InputWithIconButton from '../../forms/fields/InputWithIconButton';

import './feedback.scss';

interface FeedbackProps {
    UserStore?: any;
    AuthStore?: any;
}

class Feedback extends React.Component<FeedbackProps, any> {
    state = {
        isOpen: false,
        message: ''
    };

    openMessageBox = () => {
        this.setState({ isOpen: true });
    }

    closeMessageBox = () => {
        this.setState({
            isOpen: false,
            message: ''
        });
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    onSubmitHandler = () => {
        const { UserStore } = this.props;
        const { message } = this.state;

        UserStore.sendFeedback({ message })
            .then(() => {
                toast.success("Thank you for contributing to the improvement of the experience!", { theme: 'dark', position: 'bottom-right', icon: false });
                this.closeMessageBox();
            })
            .catch(() => {
                toast.error("Failed to send feedback, please try again", { theme: 'dark', position: 'bottom-right', icon: false });
            });
    };

    renderMessageBox = () => {
        const { message } = this.state;

        return (
            <div className="adapter-feedback-message">
                

                <div className="header">
                    <AnnouncementIcon />
                    <span style={{ flex: 1 }}>Provide Feedback</span>
                    <CloseIcon
                        onClick={this.closeMessageBox}
                        style={{ cursor: 'pointer' }}
                    />
                </div>

                <p className="content">
                    This is an early release of Adapter. Please provide any feedback here, we appreciate your input.
                </p>

                <p className="content">
                    Do not submit any personally identifying information in your message (e.g., name, birthday, SSN, etc).
                </p>

                <InputWithIconButton
                    darkMode
                    fieldId="message"
                    value={message}
                    required={true}
                    placeholder="Submit feedback..."
                    onChange={this.onChangeHandler}
                    onClick={this.onSubmitHandler}
                    onEnterKey={this.onSubmitHandler}
                    Icon={SendIcon}
                />

                <p className="help">
                    Need help? <a href="https://www.adapter.com" target='_blank'>Contact us</a>
                </p>
            </div>
        );
    }

    render() {
        const { AuthStore } = this.props;
        const { isOpen } = this.state;

        if (!AuthStore.isAuthenticated) {
            return (<></>);
        }

        if (isOpen) {
            return this.renderMessageBox();
        }

        return (
            <div className="adapter-feedback-button" onClick={this.openMessageBox}>
                <AnnouncementIcon title="Feedback" />
            </div>
        );
    }
}

export default inject('UserStore', 'AuthStore')(observer(Feedback));