import * as React from "react";
import { Arrow } from "./Arrow";

import './ArrowContainer.scss';

function getCurrentRotation(el: any) {
    const computedStyle = window.getComputedStyle(el, null);
    var transform = computedStyle.getPropertyValue("-webkit-transform") ||
        computedStyle.getPropertyValue("-moz-transform") ||
        computedStyle.getPropertyValue("-ms-transform") ||
        computedStyle.getPropertyValue("-o-transform") ||
        computedStyle.getPropertyValue("transform") ||
        "fail...";

    if (transform !== "none") {
        let values = transform
            .split('(')[1]
            .split(')')[0]
            .split(',');

        const a = values[0];
        const b = values[1];

        // @ts-ignore
        return Math.round(Math.atan2(b, a) * (180 / Math.PI));
    }

    return 0;
}

export default class ArrowContainer extends React.Component<any, any> {
    state = {
        isTracking: false,
        mouseX: 0,
        mouseY: 0,
        numberOfArrows: 0
    }

    ref: any = null;

    constructor(props: any) {
        super(props);
       
        this.ref = React.createRef();
     }

    componentDidMount(): void {
        document.body.addEventListener('mouseenter', this.handleMouseEnter);
        document.body.addEventListener('mousemove', this.handleMouseMove);
        document.body.addEventListener('mouseleave', this.handleMouseLeave);

        const el = this.ref.current;
        const { width, height } = el.getBoundingClientRect();

        const arrowsThatFit = Math.floor(width / 34) * Math.floor(height / 34);
        this.setState({ numberOfArrows: arrowsThatFit });
    }

    lookAtDefault = () => {
        const { isTracking } = this.state;

        // prevent race condition
        if (isTracking) {
            return;
        }

        const icons = document.querySelectorAll('.adapter-delight-arrow');
        const desiredRotation = 360;

        icons.forEach((icon) => {
            let currentRotation = getCurrentRotation(icon);
            // 45deg because the arrow itself is 45deg off center in the SVG
            if (currentRotation < 45) {
                currentRotation += 360; // add 360deg to make the math easier to understand
            }

            // if the current rotation is on one side of the rotational axis, move left; else move right
            if (currentRotation > desiredRotation) {
                // @ts-ignore
                icon.style.transform = `rotate(${currentRotation - 1}deg)`;
            } else {
                // @ts-ignore
                icon.style.transform = `rotate(${currentRotation + 1}deg)`;
            }
        });

        if (!isTracking) {
            requestAnimationFrame(this.lookAtDefault)
        }
    }

    lookAtMouse = () => {
        const { isTracking, mouseX, mouseY } = this.state;

        // prevent race condition
        if (!isTracking) {
            return;
        }

        const icons = document.querySelectorAll('.adapter-delight-arrow');

        icons.forEach((icon) => {
            const x = icon.getBoundingClientRect().left + (icon.clientWidth / 2);
            const y = icon.getBoundingClientRect().top + (icon.clientHeight / 2);

            const percentRotated = parseFloat(getComputedStyle(icon).getPropertyValue('offset-distance'));
            const currentRotation = 360 * percentRotated * 0.01;

            // angle in the plane (in radians) between the positive x-axis and the ray from (0, 0) to the point (x, y), for Math.atan2(y, x).
            const radian = Math.atan2(y - mouseY, x - mouseX);
            const rotationFromZero = (radian * (180 / Math.PI)) - 45;

            // @ts-ignore
            icon.style.transform = `rotate(${rotationFromZero - currentRotation}deg)`;
        });

        if (isTracking) {
            requestAnimationFrame(this.lookAtMouse)
        }
    }

    handleMouseEnter = (event: any) => {
        this.setState({
            isTracking: true,
            mouseX: event.pageX,
            mouseY: event.pageY
        }, () => requestAnimationFrame(this.lookAtMouse));
    }

    handleMouseMove = (event: any) => {
        this.setState({
            mouseX: event.pageX,
            mouseY: event.pageY,
        });
    }

    handleMouseLeave = () => {
        this.setState({ isTracking: false }, () => requestAnimationFrame(this.lookAtDefault));
    }

    componentWillUnmount(): void {
        document.body.removeEventListener('mouseenter', this.handleMouseEnter);
        document.body.removeEventListener('mousemove', this.handleMouseMove);
        document.body.removeEventListener('mouseleave', this.handleMouseLeave);
    }

    render() {
        const { style, children } = this.props;
        const { numberOfArrows } = this.state;
        const arrows = [];

        for (let i=0; i<numberOfArrows; i++) {
            arrows.push(<Arrow key={i} />)
        }

        return (
            <section id="adapter-delight-arrowcontainer" ref={this.ref} style={style || {}}>
                <div className="content">{children}</div>
                {arrows}
            </section>
        );
    }
}