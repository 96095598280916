import React from 'react';
import Notice from 'components/common/Notice';

const FailedOption = ({ text }) => {
    return (
        <>
            <Notice text='Job Failed' error />

            <div>{text}</div>
        </>
    );
};

export default FailedOption;