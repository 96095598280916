import React from 'react';

import Spinner from './Spinner';

import './Loading.scss';

const Loading = ({ modal = true }) => (
    modal
        ? (
            <div className="adapter-modal">
                <div className="adapter-loading">
                    <Spinner />
                </div>
            </div>
        )
        : (
            <div className="adapter-loading">
                <Spinner />
            </div>
        )


);

export default Loading;