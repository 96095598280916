import React from "react";
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";

import ArrowContainer from "components/delight/ArrowContainer"

interface VerificationPageProps {
  AuthStore?: any;
}

interface VerificationState {
  email: string | undefined;
}

class VerificationPage extends React.Component<VerificationPageProps, VerificationState> {

  onResendHandler = () => {
    const { AuthStore } = this.props;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("session_token");

    AuthStore
      // @ts-ignore
      .resendVerification({ token })
      .catch(() => {
        toast.error("Oops! Something went wrong, please try again.", { icon: false });
      });
  }

  renderPage = () => {
    return (
      <>
        <div className="flex-1"></div>

        <h1 className="mb-6">Verify your account</h1>

        <h2 className="mb-4">
          Please check your email and click the activation link to verify your account.
        </h2>

        <p className="mt-10">
          Didn&apos;t receive an email?{" "}
          <a href="#" onClick={this.onResendHandler}
            className="font-bold leading-6"
          >
            Try again
          </a>
        </p>

        <div className="flex-1"></div>
      </>
    );
  }

  render() {
    return (
      <div className="flex-1 flex bg-white">
        <ArrowContainer style={{ background: `linear-gradient(45deg, #53389E 0%, #7F56D9 100%)` }}>
          <span>Achieve more daily with the  power of knowledge and automation.</span>
        </ArrowContainer>

        <div className="p-8 flex-1 flex flex-col items-center overflow-y-auto">
          {this.renderPage()}
        </div>
      </div>
    );
  }
}

export default inject("AuthStore")(observer(VerificationPage));
