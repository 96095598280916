import React from 'react';

const Google = ({ color }: { color: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
        <path d="M30.0014 16.3114C30.0014 15.1603 29.9061 14.3203 29.6998 13.4492H16.2871V18.6447H24.1601C24.0014 19.9359 23.1442 21.8803 21.2394 23.1869L21.2127 23.3609L25.4536 26.5805L25.7474 26.6092C28.4458 24.167 30.0014 20.5736 30.0014 16.3114Z" fill={color || "#4285F4"}/>
        <path d="M16.2863 29.9998C20.1434 29.9998 23.3814 28.7553 25.7466 26.6086L21.2386 23.1863C20.0323 24.0108 18.4132 24.5863 16.2863 24.5863C12.5086 24.5863 9.30225 22.1441 8.15929 18.7686L7.99176 18.7825L3.58208 22.127L3.52441 22.2841C5.87359 26.8574 10.699 29.9998 16.2863 29.9998Z" fill={color || "#34A853"}/>
        <path d="M8.16013 18.769C7.85855 17.8979 7.68401 16.9645 7.68401 16.0001C7.68401 15.0356 7.85855 14.1023 8.14426 13.2312L8.13627 13.0456L3.67132 9.64746L3.52524 9.71556C2.55703 11.6134 2.00146 13.7445 2.00146 16.0001C2.00146 18.2556 2.55703 20.3867 3.52524 22.2845L8.16013 18.769Z" fill={color || "#FBBC05"}/>
        <path d="M16.2864 7.4133C18.9689 7.4133 20.7784 8.54885 21.8102 9.4978L25.8419 5.64C23.3658 3.38445 20.1435 2 16.2864 2C10.699 2 5.8736 5.1422 3.52441 9.71549L8.14345 13.2311C9.30229 9.85555 12.5086 7.4133 16.2864 7.4133Z" fill={color || "#EB4335"}/>
    </svg>
);

export default Google;